<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="info-card mb-2">
          <b-row>
            <b-col cols="12" md="6" lg="2">
              <b-form-group>
                {{ $t('report_event') }}
                <b-select @change="selectEvent" v-model="filter_event" :options="events"></b-select>
                <b-form-checkbox class="mt-2 report-switch-small" switch v-model="includeArchivedEvents" :value="true" :unchecked-value="false">{{ $t('reports_include_archived_events') }}</b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col  cols="12" md="6" lg="3">
              {{ $t('report_date_range_start') }} 
              <date-picker v-model="date_range.start"></date-picker>
            </b-col>
            <b-col  cols="12" md="6" lg="3">
              {{ $t('report_date_range_end') }} 
              <date-picker v-model="date_range.end"></date-picker>
            </b-col>
            <b-col  cols="12" md="6 " lg="2" class="align">
              <b-form-group class="block mb-3 mb-md-0">
                <p>{{ $t('report_box_office') }}</p>
                <b-form-checkbox switch v-model="filter_box_office" :value="true" :unchecked-value="false"></b-form-checkbox>  
              </b-form-group>
            </b-col>
            <b-col  cols="12" md="12 " lg="2" class="align">
              <b-button class=" text-right btn-filter mt-0" variant="new" v-on:click="getReportData">{{ $t('report_filter_btn') }}</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="20" sm="6" cols="12">
        <b-card class="info-card center">
          <span>{{ data.paid_orders_count }}</span><br/>
          <span>{{ $t('report_paid_orders') }}</span>
        </b-card>
      </b-col>
      <b-col lg="20" sm="6" cols="12">
        <b-card class="info-card center">
          <span>{{ data.ticket_count }}</span><br/>
          <span>{{ $t('report_tickets_sold') }}</span>
        </b-card>
      </b-col>
      <b-col lg="20" sm="6" cols="12">
        <b-card class="info-card center">
          <span>{{ data.claimed_tickets_count }}</span><br/>
          <span>{{ $t('report_tickets_claimed') }}</span>
        </b-card>
      </b-col>
      <b-col lg="20" sm="6" cols="12">
        <b-card class="info-card center">
          <span>{{ data.free_tickets_count }}</span><br/>
          <span>{{ $t('report_free_tickets') }}</span>
        </b-card>
      </b-col>
      <b-col lg="20" sm="6" cols="12">
        <b-card class="info-card center">
          <span>{{ data.total_revenue | currency }}</span><br/>
          <span>{{ $t('report_total_revenue') }}</span>
        </b-card>
      </b-col>
      <b-col v-if="isEventStart" lg="20" sm="6" cols="12">
        <b-card class="info-card center">
          <span>{{ data.total_scans }}</span><br/>
          <span>{{ $t('reports_tickets_scanned') }}</span>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4" v-if="isEventStart">
      <b-col>
        <b-card>
          <b-card-title>{{ $t('reports_tickets_scans') }}</b-card-title>
          <div class="p-2">
            <b-row>
              <b-col sm="3" md="2">
                <b-form-group :label="$t('reports_period')">
                  <b-form-select v-model="scansOption">
                    <b-form-select-option value="hourly">{{ $t('reports_tickets_scans_hourly') }}</b-form-select-option>
                    <b-form-select-option value="daily">{{ $t('reports_tickets_scans_daily') }}</b-form-select-option>
                    <b-form-select-option value="weekly">{{ $t('reports_tickets_scans_weekly') }}</b-form-select-option>
                    <b-form-select-option value="monthly">{{ $t('reports_tickets_scans_monthly') }}</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="scansOption === 'hourly'">
                <b-row>
                  <b-col>
                    <date-time-picker v-model="scansStartsAt" :label="$t('report_date_range_start')"></date-time-picker>
                  </b-col>
                  <b-col>
                    <date-time-picker v-model="scansEndsAt" :label="$t('report_date_range_end')"></date-time-picker>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="scansOption === 'daily'">
                <b-row>
                  <b-col>
                    <date-picker v-model="scansStartsAt" :label="$t('report_date_range_start')"></date-picker>
                  </b-col>
                  <b-col>
                    <date-picker v-model="scansEndsAt" :label="$t('report_date_range_end')"></date-picker>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2">
                <span class="label-block"></span>
                <b-button :disabled="scanLoading" class="text-right mt-0" variant="new" @click="getTicketsScansData()">
                  <b-icon v-if="scanLoading" icon="three-dots" animation="cylon" class="mr-2"></b-icon> {{ $t('report_filter_btn') }}
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-cart-text>
            <apexchart ref="ticket_scans" width="99%" height="300px" type="line" :options="chartOptionsScans"
                       :series="seriesScans"></apexchart>

          </b-cart-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col lg="6" sm="12" cols="12">
        <b-card>
          <b-card-title>{{ $t('report_sales_per_day') }}</b-card-title>
          <b-cart-text>
            <apexchart ref="chartsales_per_day" width="99%" height="300px" type="line" :options="chartOptionsSales"
                        :series="seriesSales"></apexchart>
            
          </b-cart-text>
        </b-card>
      </b-col>
      <b-col lg="6" sm="12" cols="12">
        <b-card>
          <b-card-title>{{ $t('report_sales_per_day_cumulative') }}</b-card-title>
          <b-cart-text>
            <apexchart ref="chartsales_cumulative" width="99%" height="300px" type="line" :options="chartOptionsSalesCumulative"
                        :series="seriesSalesCumulative"></apexchart>
          </b-cart-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col lg="8" sm="12" cols="12">
        <b-card v-if="isEventStart" class="mt-4">
          <b-row>
            <b-col>
              <b-card-title>{{ $t('reports_scans_by_type') }}</b-card-title>
            </b-col>
            <b-col cols="6" class="text-right">
              <div class="p-2">
                <b-icon icon="search" @click="searchScansPerTypeVisibility = !searchScansPerTypeVisibility" class="mr-2 cursor-pointer"></b-icon>
                <b-input v-if="searchScansPerTypeVisibility" v-model="searchScansPerType" type="search" class="sold-per-type-search mr-2"/>
                <b-icon @click="scans_per_type_open = !scans_per_type_open" v-b-toggle="'scans_per_type'" :icon="scans_per_type_open ? 'arrows-angle-contract' : 'arrows-angle-expand'" class="toggle-icon"></b-icon>
              </div>
            </b-col>
          </b-row>
          <b-collapse visible id="scans_per_type">
            <b-cart-text>
              <div v-for="(tickets, group) in productsScan" :key="group" class="mb-3">
                <div :class="{'bg-light':true,'p-3':group !== 'event_default_group'}">{{ group !== 'event_default_group' ? group : '' }}</div>
                <b-row v-for="ticket in tickets" :key="ticket.name" class="border-bottom py-3 w-100 m-0 ticket-row">
                  <b-col cols="12" sm="12" lg="5"  class="mb-3 mb-md-0">{{ ticket.name }}</b-col>
                  <b-col cols="6" sm="6" lg="3" >
                    <b-progress :value="Math.ceil((ticket.scans/ticket.sold) * 100)"></b-progress>
                  </b-col>
                  <b-col v-if="ticket.sold === -1" cols="3" sm="3" lg="2" >100</b-col>
                  <b-col v-else cols="3" sm="3" lg="2" >{{ ticket.perc }}%</b-col>
                  <b-col cols="3" sm="3" lg="2" class="text-right">{{ ticket.scans }}/{{ ticket.sold >=0 ? ticket.sold : $t('product_stock_unlimited_icon') }}</b-col>
                </b-row>
              </div>
            </b-cart-text>
          </b-collapse>
        </b-card>
        <b-card class="mt-4">
          <b-row>
            <b-col>
              <b-card-title>{{ $t('report_tickets_sold_per_type') }}</b-card-title>
            </b-col>
            <b-col cols="6" class="text-right">
              <div class="p-2">
                <b-icon icon="search" @click="searchSoldPerTypeVisibility = !searchSoldPerTypeVisibility" class="mr-2 cursor-pointer"></b-icon>
                <b-input v-if="searchSoldPerTypeVisibility" v-model="searchSoldPerType" type="search" class="sold-per-type-search mr-2"/>
                <b-icon @click="sold_per_type_open = !sold_per_type_open" v-b-toggle="'sold_per_type'" :icon="sold_per_type_open ? 'arrows-angle-contract' : 'arrows-angle-expand'" class="toggle-icon"></b-icon>
              </div>
            </b-col>
          </b-row>
          <b-collapse visible id="sold_per_type">
            <b-cart-text>
              <div v-for="(groupProducts, group) in products" :key="group" class="mb-1">
                <div :class="{'bg-light':true,'p-2':group !== 'event_default_group'}">{{ group !== 'event_default_group' ? group : '' }}</div>
                <b-row v-for="ticket in groupProducts" :key="ticket.name" class="border-bottom py-2 w-100 m-0 ticket-row">
                  <b-col cols="12" sm="12" lg="5"  class="mb-3 mb-md-0">{{ ticket.name }}</b-col>
                  <b-col cols="6" sm="6" lg="3" >
                    <b-progress :value="Math.ceil((ticket.times_sold/ticket.stock) * 100)"></b-progress>
                  </b-col>
                  <b-col v-if="ticket.stock === -1" cols="3" sm="3" lg="2" >100</b-col>
                  <b-col v-else-if="ticket.perc === 100" cols="3" sm="3" lg="2" ><b-badge variant="success" class="text-uppercase">{{ $t('report_sold_out') }}</b-badge></b-col>
                  <b-col v-else cols="3" sm="3" lg="2" >{{ ticket.perc }}%</b-col>
                  <b-col v-if="filter_event && $can('reports_sales_by_tickettype_view')" cols="3" sm="3" lg="2" class="text-right">
                    <router-link :to="{name: 'ReportSalesPerTickettype', query: {event: filter_event, ticket: ticket.product_id}}">{{ ticket.times_sold }}/{{  ticket.stock === -1 ? $t('product_stock_unlimited_icon') : ticket.stock }}</router-link>
                  </b-col>
                  <b-col v-else cols="3" sm="3" lg="2" class="text-right">{{ ticket.times_sold }}/{{ ticket.stock >=0 ? ticket.stock : $t('product_stock_unlimited_icon') }}</b-col>
                </b-row>
              </div>
            </b-cart-text>
          </b-collapse>
                
        </b-card>
      </b-col>
      <b-col lg="4" sm="12" cols="12" class="mt-4 ">
        <b-card class="info-card center mb-3 mt-0 mr-0">
          <span>{{ data.age_avg }}</span><br/>
          <span>{{ $t('report_age_avg') }}</span>
        </b-card>
        <b-card>
          <b-card-title>{{ $t('report_age') }}</b-card-title>
          <b-cart-text>
            <apexchart ref="chartages"  width="99%" type="bar" :options="chartOptionsAges" :series="seriesAges"></apexchart>
            <div class="mb-5">
              <b-row class="flexaround" v-for="(amount, age) in ages" :key="'age-' + age">
                <b-col class=" text-left"  cols="5">{{age}}</b-col>
                <b-col class=" text-right" cols="5">{{amount}}%</b-col>
              </b-row>
            </div>
          </b-cart-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="pie-chart-row">
      <b-col cols="12"   lg="4"  class="mb-4">
        <b-card>
          <b-card-title>{{ $t('report_gender') }}</b-card-title>
          <b-cart-text>
            <apexchart ref="chartgenders" width="99%" type="donut" :options="chartOptions"
                        :series="series"></apexchart>
          </b-cart-text>
        </b-card>
      </b-col>
      <b-col cols="12"   lg="4" class="mb-4">
        <b-card>
          <b-card-title>{{ $t('report_cities') }}</b-card-title>
          <b-cart-text>
            <apexchart ref="chartcities" width="99%" type="donut" :options="chartOptions"
                        :series="series"></apexchart>
          </b-cart-text>
        </b-card>
      </b-col>
      <b-col cols="12"   lg="4" class="mb-4">
        <b-card>
          <b-card-title>{{ $t('report_payment_methods') }}</b-card-title>
          <b-cart-text>
            <apexchart ref="chartpayment_methods" width="99%" type="donut" :options="chartOptions"
                        :series="series"></apexchart>
          </b-cart-text>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="pie-chart-row" v-if="custom_questions">
      <b-col v-for="question in custom_questions" :key="question.question" cols="12" lg="4" class="mb-4 mt-4">
        <b-card>
          <b-card-title>{{question.question}}</b-card-title>
          <b-card-text>
            <table>
              <tr v-for="answer in question.answers" :key="answer.answer">
                <td>{{ answer.answer }}:</td>
                <td style="padding-left:10px">{{ answer.count }}</td>
              </tr>
            </table>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import api from '../api'
import DatePicker from "@/components/DatePicker";
import DateTimePicker from "@/components/DateTimePicker";
import moment from 'moment'
import {groupBy} from "@/functions";

export default {
  components: {
    DatePicker,
    DateTimePicker
  },
  name: 'ReportsOverview',
  data() {
    return {
      event_created_dates: {},
      filter_box_office: false,
      filters: {},
      options_event: [],
      includeArchivedEvents: false,
      filter_event: "",
      ages: {},
      counter: 0,
      date_range: {
        start: new Date( Date.now() - (14 * 24 * 60 * 60 * 1000) ),
        end: new Date()
      },
      custom_questions: {},
      data: {},
      chartOptionsAges: {
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function(val){
              return val.toFixed(0).padStart(2, '0')
            }
          }
        },
        // responsive: [{
        //   breakpoint: 880,
        //   options: {
        //     chart: {
        //       width:700
        //     }
        //   }
        // }]
      },
      seriesAges: [{
        name: this.$t('report_age'),
        data: []
      }],
      chartOptionsSales: {
        xaxis: {
          categories: [],
          tooltip: false
        },
        yaxis: {
          labels: {
            formatter: function(val){
              return val.toFixed(0).padStart(2, '0')
            }
          }
        }
      },
      seriesSales: [{
        name: this.$t('report_tickets'),
        data: []
      }],
      chartOptionsScans: {
        xaxis: {
          categories: [],
          tooltip: false
        },
        yaxis: {
          labels: {
            formatter: function(val){
              return val.toFixed(0).padStart(2, '0')
            }
          }
        }
      },
      seriesScans: [{
        name: 'Scans',
        data: []
      }],
      chartOptionsSalesCumulative: {
        xaxis: {
          categories: [],
          tooltip: false
        },
        yaxis: {
          labels: {
            formatter: function(val){
              return val.toFixed(0).padStart(2, '0')
            }
          }
        }
      },
      seriesSalesCumulative: [{
        name: this.$t('report_tickets'),
        data: []
      }],
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
        },
        labels: [],
        responsive: [
          {
          breakpoint: 880,
          options: {
            chart: {
              width:420
            }
          }
        },{
          breakpoint: 500,
          options: {
            chart: {
              width:320
            }
          }
        }]
      },
      reportLoopKeys: ['genders', 'cities', 'payment_methods'],
      scansOption: 'hourly',
      scans_per_type_open: true,
      sold_per_type_open: true,
      scansStartsAt: null,
      scansEndsAt: null,
      scanLoading: false,
      total_scans: 0,
      searchSoldPerType: null,
      searchSoldPerTypeVisibility: false,
      searchScansPerType: null,
      searchScansPerTypeVisibility: false,
    }
  },
  created() {
    document.title = this.$t('backend_title_reports')
    if(this.$store.state.user) {
      if(!this.$can('reports_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.report().getFilters().then(filter => {
      this.filters = filter
      this.options_event = this.filters.events.map(filter => (
          {value: filter.id, text: filter.name, status: filter.status, continuous: filter.continuous, starts_at: filter.starts_at}
      ))

      // Create array with all start dates of the events
      for(let event in filter.events){
        this.event_created_dates[ filter.events[event].id ] = filter.events[event].created_at
      }
    })
    this.getReportData()
  },
  computed: {
    events() {
      if(this.includeArchivedEvents) {
        return this.options_event;
      }
      return this.options_event.filter(event => event.status === 'live')
    },
    products() {
      if (!this.data.products) {
        return []
      }
      const products = this.data.products.filter(t => this.searchSoldPerType && this.searchSoldPerType.length ? t.name.toLowerCase().includes(this.searchSoldPerType.toLowerCase()) : true);
      return groupBy(products.map((product) => {
        product.perc = Math.round((product.times_sold / product.stock) * 10000) / 100
        return product
      }), p => p.group)
    },
    productsScan() {
      if (!this.data.scans_by_type) {
        return []
      }
      const products = this.data.scans_by_type.filter(t => this.searchScansPerType && this.searchScansPerType.length ? t.name.toLowerCase().includes(this.searchScansPerType.toLowerCase()) : true);
      return groupBy(products.map((product) => {
        product.perc = product.sold ? Math.round((product.scans / product.sold) * 10000) / 100 : 0;
        return product
      }), p => p.group)
    },
    isEventStart() {
      if(!this.filter_event) {
        return false;
      }
      const event = this.filters.events.find(e => e.id === parseInt(this.filter_event));
      return moment() > moment(event.starts_at);
    }
  },
  methods: {
    selectEvent(){
      if( this.filter_event in this.event_created_dates ){
        this.date_range.start = this.event_created_dates[this.filter_event]

        if( moment(this.date_range.end) < moment(this.event_created_dates[this.filter_event]) ){
          this.date_range.end = this.event_created_dates[this.filter_event]
        }
      }
      const event = this.filters.events.find(e => e.id === parseInt(this.filter_event));
      if(event.continuous) {
        this.scansStartsAt = moment().subtract(7, 'days');
        this.scansEndsAt = moment();
      }
      else {
        this.scansStartsAt = event.starts_at;
        this.scansEndsAt = event.ends_at;
      }
    },
    getReportData() {

      api.report().getData(this.filter_event, this.date_range.start, this.date_range.end, this.filter_box_office, Intl.DateTimeFormat().resolvedOptions().timeZone).then(data => {

        const event = this.events.find(e => e.value === parseInt(this.filter_event))

        data.total_revenue = data.total_revenue - data.refund_amount

        this.data = data

        if(this.filter_event){
          api.report().getCustomAnswersData(this.filter_event, this.date_range.start, this.date_range.end).then(answerData => {

            let tmpArr = {}
            let questions = []
            let counter = 0
          
            for (const key in answerData) {
              if (Object.prototype.hasOwnProperty.call(answerData, key)) {
                if (answerData[key]) {
                  for (const question in answerData[key]) {
                      if (Object.prototype.hasOwnProperty.call(answerData[key], question)) {
                          counter = 0;
                          let answers = [];

                          for (const answer in answerData[key][question]) {
                              if (Object.prototype.hasOwnProperty.call(answerData[key][question], answer)) {
                                  answers.push({ answer: answer, count: answerData[key][question][answer] });
                                  counter += 1;
                              }
                          }

                          questions.push({ question: question, answers: answers });
                      }
                  }
                }
              }
            }
            this.counter = counter
            if(tmpArr){
              this.custom_questions = questions
            }
          })
        }

        // Loop through all of the keys to fill the reports with the correct data
        this.reportLoopKeys.forEach(loopKey => {
          if (this.data[loopKey]) {
            let labels = []
            let series = []
            for (let label in this.data[loopKey]) {
              let pushLabel = label
              if (label === '') {
                pushLabel = 'unkown'
              }
              if (loopKey === 'genders' || pushLabel === 'unkown') {
                pushLabel = this.$t('report_' + loopKey + '_' + pushLabel)
              }
              labels.push(pushLabel)
              series.push(this.data[loopKey][label])
            }
            let chartRef = 'chart' + loopKey
            this.$refs[chartRef].updateOptions({labels: labels})
            this.$refs[chartRef].updateSeries(series)
          }
        })


        // Sales per day
        if (this.data.sales_per_day) {
          let labels = []
          let series = []
          let seriesCumulative = []
          let seriesCumulativeLabels = []
          let date = new Date(this.date_range.start)
          let end_date = new Date(this.date_range.end)

          let amountCumulative = 0

          while (date <= end_date) {

            let key = date.getFullYear()
            let month = (date.getMonth()+1).toString()
            let day = date.getDate().toString()

            labels.push(date.toLocaleDateString())

            if(event && !event.continuous) {
              const days = Math.round(moment(event.starts_at).diff(date, 'hours') / 24);
              if(days > 0) {
                seriesCumulativeLabels.push(this.$t('report_days_before_event', {days: days}));
              }
              else if(days === 0) {
                seriesCumulativeLabels.push(this.$t('report_event_day', {days: days}));
              }
              else {
                seriesCumulativeLabels.push(this.$t('report_days_from_event', {days: Math.abs(days)}));
              }
            }
            else {
              seriesCumulativeLabels.push(date.toLocaleDateString())
            }

            if(month.length == 1){  month = '0' + month }
            if(day.length == 1){ day = '0' + day}
            key = key + '-' + month + '-' + day

            let amount = 0
            if (this.data.sales_per_day[key]) {
              amount = this.data.sales_per_day[key]
              amountCumulative += this.data.sales_per_day[key]
            }
            series.push(amount)
            seriesCumulative.push(amountCumulative)
            var newDate = date.setDate(date.getDate() + 1)
            date = new Date(newDate);
          }

          this.$refs.chartsales_per_day.updateOptions({xaxis: {categories: labels}})
          this.$refs.chartsales_cumulative.updateOptions({xaxis: {categories: seriesCumulativeLabels}})
          this.seriesSales = [{data: series}]
          this.seriesSalesCumulative = [{data: seriesCumulative}]
        }

        // Ages
        if(this.data.ages){
          let labels = []
          let series = []
          Object.keys(this.data.ages).forEach( key => {
            labels.push(key)
            series.push(this.data.ages[key])

            this.ages[key] = Number((this.data.ages[key] / data.ticket_count) * 100).toFixed(0)

          })
          this.seriesAges = [{data: series}]
          this.$refs.chartages.updateOptions({xaxis: {categories: labels}})
        }
      })

      
      api.report().getCitiesData(this.filter_event, this.date_range.start, this.date_range.end, this.filter_box_office, Intl.DateTimeFormat().resolvedOptions().timeZone).then(data => {

        this.data.cities = data

        // Loop through all of the keys to fill the reports with the correct data
        this.reportLoopKeys.forEach(loopKey => {
          if (this.data[loopKey]) {
            let labels = []
            let series = []
            for (let label in this.data[loopKey]) {
              let pushLabel = label
              if (label === '') {
                pushLabel = 'unkown'
              }
              labels.push(pushLabel)
              series.push(this.data[loopKey][label])
            }
            let chartRef = 'chart' + loopKey
            this.$refs[chartRef].updateOptions({labels: labels})
            this.$refs[chartRef].updateSeries(series)
          }
        })
      })
    },
    getTicketsScansData(period = null) {
      if(!period) {
        period = this.scansOption;
      }
      this.$refs.ticket_scans.updateOptions({xaxis: {categories: []}})
      this.seriesScans = [{data: []}]

      const start = this.scansStartsAt;
      const end = this.scansEndsAt;

      this.scanLoading = true;

      api.report().getTicketsScansData(period, this.filter_event, start, end, this.filter_box_office, Intl.DateTimeFormat().resolvedOptions().timeZone).then(data => {
        if(period === 'hourly') {
          this.$refs.ticket_scans.updateOptions({xaxis: {categories: data.map(d => {
            if(d.hour.length <= 5) {
              return d.hour;
            }

            const date = new Date(d.hour).toLocaleDateString();
            return date + ' ' + d.hour.substring(d.hour.length -5)
          }
          )}})
        }
        else if(period === 'daily') {
          this.$refs.ticket_scans.updateOptions({xaxis: {categories: data.map(d => new Date(d.date).toLocaleDateString())}})
        }
        else if(period === 'weekly') {
          this.$refs.ticket_scans.updateOptions({xaxis: {categories: data.map(d => d.week)}})
        }
        else {
          this.$refs.ticket_scans.updateOptions({xaxis: {categories: data.map(d => d.month)}})
        }
        this.seriesScans = [{data: data.map(d => d.total_tickets_scanned)}]
        this.scanLoading = false;
      }).catch(() => {
        this.scanLoading = false;
      });
    },
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('reports_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<style>
.report-switch-small{
  font-size:0.8rem
}
.block p{
  margin-bottom: 0;
  text-align: center;
}

.block .custom-control{
 text-align: center;
}
.align{
  display: flex;
  align-items: center;
  justify-content: center;
}
.apexcharts-tooltip.apexcharts-theme-dark span {
  color: #fff;
}
@media (max-width:992px) {
  .block p{
  text-align: left;
  margin-right: 15px;
  width:80%;
}
.block{
width: 100%;
}
.block > div{
  display: flex;
align-items: center;
margin: 10px 0;
}
.block .custom-control{
 text-align: center;
}
}

.hourly-time .dropdown-menu {
  max-height: 200px;
  overflow: scroll;
}

.sold-per-type-search {
  width: 200px !important;
  height: 40px !important;
  display: inline-block !important;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flexaround{
  justify-content: space-around;
  margin: 10px 0;
  width: 100%;
}

.tickets-filled {
  height: 6px;
  border-radius: 50px;
  background: linear-gradient(0.25turn, #d2ecff, #008ffb);
}

.margin-middle {
  margin-top: auto;
  margin-bottom: auto;
}

div.card {
  -webkit-box-shadow: 2px 2px 3px 0px rgba(235, 235, 235, 1);
  -moz-box-shadow: 2px 2px 3px 0px rgba(235, 235, 235, 1);
  box-shadow: 2px 2px 3px 0px rgba(235, 235, 235, 1);
}

div.card .card-title {
  background-color: #FFF;
  color: #193e58;
  border-bottom: 1px solid #F5F5F5;
  font-weight: normal
}

.info-card.center .card-body {
  text-align: center;
}

.info-card span:first-of-type {
  font-size: 28pt
}

.pie-chart-row div.card{
  height:100%
}

.btn-filter{
  height:50px;
  width: 100%;
  text-align: center!important;
}
@media (min-width: 992px) {
  .col-lg-20 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.ticket-row:last-child {
  border-bottom: none !important;
}
.toggle-icon {
  cursor: pointer;
}
.toggle-icon:focus {
  outline: none !important;;
}
.label-block {
  display: block;
  height: 31px;
}
</style>
