<template>

  <div v-if="event">
  <div>
  <b-alert v-if="event.isArchived" variant="danger" show class="text-center">
    {{ $t('event_is_archived') }}
  </b-alert>
  <div class="d-flex  flex-wrap event-poses">
    <div class="bigDiv px-0 pr-lg-3 pl-lg-2 order-2 order-lg-1 mb-3">
      <b-row class="mb-3 ">
        <b-col cols="9"><h1 class="mb-0">{{ $t('event_pos_title', event) }}</h1></b-col>
        <b-col cols="3">
          <b-button v-if="$can('event_point_of_sale_create')" class="btn-new float-right px-3 py-3" @click="newPos()" :disabled="event.isArchived">{{ $t('event_pos_create') }}</b-button>
          <div v-else class="float-right px-3 py-3" style="height: 56px;"></div>
        </b-col>
      </b-row>
      <div class="tableBig">
      <b-table class="table-container TimeSlot pos-table" :items="pointsOfSale" :fields="[{key: 's', label: ''},'status', 'name', 'actions']">
        <template v-slot:cell(s)="{item}">
          <b-icon v-if="!item.products.length && !item.payment_methods.some(pm => pm.online)" icon="exclamation-circle-fill" variant="danger" v-b-tooltip.hover :title="$t('pos_no_products_and_pm_are_configured')"></b-icon>
          <b-icon v-else-if="!item.products.length" icon="exclamation-circle-fill" variant="danger" v-b-tooltip.hover :title="$t('pos_no_products_are_configured')"></b-icon>
          <b-icon v-else-if="!item.payment_methods.some(pm => pm.online)" icon="exclamation-circle-fill" variant="danger" v-b-tooltip.hover :title="$t('pos_no_pm_are_configured')"></b-icon>
        </template>
        <template v-slot:cell(status)="{item}">
          <status-label :status="item.status"></status-label>
        </template>
        <template v-slot:cell(actions)="{item}">
          <b-button class="btn btn-orange-border mr-3" @click="open(item)">{{ $t('pos_event_open') }}</b-button>
          <b-button v-if="$can('event_point_of_sale_edit')" class="btn btn-row-edit mr-3" @click="edit(item)">{{ $t('manage') }}</b-button>
          <b-dropdown :text="$t('qr_code')" class="qr-show">
            <b-dropdown-text class="qr-show-container">
              <qr-code
                  :text="'https:'+getPosUrl(item)"
                  :description="$t('pos_qr_description')"
              ></qr-code>
            </b-dropdown-text>
          </b-dropdown>
          <a v-if="$can('event_point_of_sale_delete')" class="delete-btn ml-3" @click="showDeleteModal(item.id)">
            <b-img :src="require('./../assets/icons/trash.png')"/>
          </a>
        </template>
      </b-table>
        </div>
      <b-modal :title="$t('pos_modal_edit')" id="edit-pos" size="lg">
        <template v-if="pos">
          <b-form-group :label="$t('pos_name')">
            <b-form-input v-model="pos.name"></b-form-input>
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group :label="$t('pos_status')">
                <b-select @change="posStatusChange(pos)" v-model="pos.status" :options="['draft', 'live', 'sold-out', 'archived', 'test', 'box-office']"></b-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="$t('pos_language')">
                <b-select :options="languages" v-model="pos.language" text-field="label" value-field="language"></b-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="pos.status === 'box-office'">
            <b-col>
              <b-form-checkbox class="my-auto" v-model="pos.use_all_stock">{{$t('event_box_office_use_all_stock')}}</b-form-checkbox>
            </b-col>
          </b-row>
          <b-form-group :label="$t('pos_url')" v-if="pos.status !== 'box-office'">
            <b-input-group>
              <template #prepend>
                <b-input-group-text>{{ 'http://' + company.domain + '/p/' }}</b-input-group-text>
              </template>
              <b-form-input type="url" v-model="pos.url"></b-form-input>
            </b-input-group>
            <span v-if="pos && pos.url && pos.url.includes(' ')" class="text-danger d-block">{{ $t('url_cannot_spaces') }}</span>
          </b-form-group>
          
          <b-row class="mt-4">
            <b-col v-if="pos.status === 'live'">
              <b-form-group>
                <b-checkbox class="name-checkbox" v-model="pos.show_in_overview">
                  {{ $t('pos_show_in_overview') }}
                </b-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <h3 class="mt-3">{{ $t('pos_modal_edit_products') }}</h3>
          <p class="desc mb-0">{{ $t('pos_products_hint') }}</p>
          <div class="pos-product-groups">
            <vue-draggable v-model="productGroups" handle=".handle">
              <div v-for="group in productGroups" :key="`group` + group.name">
                <b-row class="product-group-row " @click="group.open ? group.open = false : group.open = true">
                  <b-col>
                    <h5>{{ group.type == 'default' ? $t('no_product_group') : group.name }}</h5>
                  </b-col>
                  <b-col cols="6"  class="text-right">
                    <b-button variant="link" class="handle">
                      <b-icon icon="arrows-move"></b-icon>
                    </b-button>
                    <b-button v-if="!group.open" variant="link">
                      <b-icon icon="chevron-right"></b-icon>
                    </b-button>
                    <b-button v-else variant="link">
                      <b-icon icon="chevron-down"></b-icon>
                    </b-button>
                  </b-col>
                </b-row>
                <div v-show="group.open" class="product-group-products mb-4">
                  <vue-draggable v-model="group.products" handle=".product-handle">
                    <div v-for="(product, p) in group.products" :key="`prod` + product.id">
                      <pos-product
                          v-if="product.product_type_id !== donation_product_id"
                          v-model="group.products[p]"
                          :sortable="true"
                          @input="checkPosCanSave"
                      ></pos-product>
                    </div>
                  </vue-draggable>
                </div>
              </div>
            </vue-draggable>
          </div>
          <h3 class="mt-4">{{ $t('pos_payment_methods') }}</h3>
          <b-row class="Rowhead">
            <b-col cols="12" md="4" class="mb-2 mb-md-3 mt-3">{{ $t('payment_method') }}</b-col>
            <b-col cols="12"  md="4" class="mb-2 mt-1 mt-md-3">{{ $t('pos_payment_method_fee_fixed') }}</b-col>
            <b-col cols="12"  md="4" class="mb-2 mt-1 mt-md-3">{{ $t('pos_payment_method_fee_percentage') }}</b-col>
          </b-row>
          <vue-draggable v-model="paymentMethods" handle=".handle" class="mb-2">

            <b-row
                v-for="(paymentMethod) in paymentMethods"
                :key="'paymentMethod' + paymentMethod.id"
                align-v="center"
                :class="[
                  paymentMethod.psd2_calc_to_customer == 'fairtix-fee' ? 'fee-row':'',
                  (( pos.status === 'box-office' && paymentMethod.show_pos) || (pos.status !== 'box-office' && paymentMethod.show_box) ) ? 'hidden' : ''
                ]"
                class="mb-2 Rowbody"
            >
              <b-col cols="7" md="3" class="mb-2 order-1 order-md-1">
                <b-form-group class="mt-2 mb-0 namePayment">
                  <b-checkbox class="name-checkbox" @input="checkPosCanSave" v-model="paymentMethod.online">{{ paymentMethod.name }}</b-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" class="order-3 order-md-2">
                <b-form-group v-show="paymentMethod.psd2_calc_to_customer != 'no'" class="mb-2 mb-md-0 ">
                  <b-input-group  style="height:45px">
                    <template #prepend>
                      <b-input-group-text  style="height:45px">{{ $t('currency') }}</b-input-group-text>
                    </template>
                    <b-form-input  style="height:45px"
                        type="number"
                        v-model="paymentMethod.fee_fixed"
                        :step="0.01"
                        :min="0"
                        :id="'paymentMethodErrorFixed' + paymentMethod.id"
                        @keyup="validatePaymentInput()"
                    ></b-form-input>
                  </b-input-group >
                  <span class="notice-message" v-if="paymentMethod.psd2_calc_to_customer == 'fairtix-fee'">
                    {{ $t('max') }}: {{ paymentMethod.payment_method_fees[0].fee_fixed_amount | currency}}
                  </span>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" class="order-4 order-md-3">
                <b-form-group v-show="paymentMethod.psd2_calc_to_customer != 'no'" class="mb-3 mb-md-0 " >
                  <b-input-group style="height:45px">
                    <b-form-input style="height:45px"
                        type="number"
                        v-model="paymentMethod.fee_percentage"
                        :step="0.01"
                        :min="0"
                        :id="'paymentMethodErrorPercentage' + paymentMethod.id"
                        @keyup="validatePaymentInput()"
                    ></b-form-input>
                    <template #append>
                      <b-input-group-text>{{ $t('perc') }}</b-input-group-text>
                    </template>
                  </b-input-group>
                  <span class="notice-message" v-if="paymentMethod.psd2_calc_to_customer == 'fairtix-fee'">
                    {{ $t('max') }}: {{paymentMethod.payment_method_fees[0].fee_percentage}}%
                  </span>
                </b-form-group>
              </b-col>
              <b-col cols="5" md="1" class="mb-2 text-right order-2 order-md-4">
                <b-button variant="link" class="handle px-0">
                  <b-icon icon="arrows-move"></b-icon>
                </b-button>
              </b-col>
            </b-row>
          </vue-draggable>
          <b-row v-if="has_donation_product">
            <b-col cols="6">
              <b-form-group :label="$t('pos_allow_donation_question')" label-cols="6">
                <b-input-group class="mt-2">
                  <b-form-checkbox switch v-model="pos.has_donation" :value="true" :unchecked-value="false"></b-form-checkbox>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <b-form-group>
                <b-form-checkbox v-model="showCountdown" :value="true" :unchecked-value="false">{{ $t('pos_countdown') }}</b-form-checkbox>
              </b-form-group>
              <b-form-group v-if="showCountdown">
                <date-time-picker v-model="pos.countdown" :label="$t('pos_countdown_datetime')"></date-time-picker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('pos_terms_conditions_url')">
                <b-form-input v-model="pos.terms_conditions_url"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('pos_custom_css')">
                <b-textarea v-model="pos.custom_css" rows="8"></b-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <span v-if="pos && pos.status === 'live' && !posHasProducts && !posHasPaymentMethods" class="text-danger d-block float-right form-control-sm p-0 m-0">{{ $t('pos_no_products_and_pm_are_configured') }}</span>
          <span v-else-if="pos && pos.status === 'live' && !posHasProducts" class="text-danger d-block float-right form-control-sm p-0 m-0">{{ $t('pos_no_products_are_configured') }}</span>
          <span v-else-if="pos && pos.status === 'live' && !posHasPaymentMethods" class="text-danger d-block float-right form-control-sm p-0 m-0">{{ $t('pos_no_pm_are_configured') }}</span>
        </template>
        <template #modal-footer="{cancel}">
          <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
          <b-button :disabled="btn_save_disabled == 1 || event.isArchived || (pos && pos.status === 'live' && (!posHasProducts || !posHasPaymentMethods)) || (pos && pos.url.includes(' '))" @click="save()" variant="success">{{ $t('save') }}</b-button>
        </template>
      </b-modal>
      <b-modal :title="$t('pos_modal_create')" id="new-pos" size="lg">
        <template v-if="pos">
          <b-form-group :label="$t('pos_name')">
            <b-form-input v-model="pos.name"></b-form-input>
          </b-form-group>
        </template>
        <template #modal-footer="{cancel}">
          <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
          <b-button @click="create()" variant="success">{{ $t('create') }}</b-button>
        </template>
      </b-modal>
    </div>
    <div class="SmallDiv px-0 pl-lg-3 pr-lg-0 order-1 order-lg-2 mb-4 mb-lg-1 ">
      <event-nav v-if="event && event.name" :event="event"></event-nav>
    </div>
  </div>
  </div>
  <b-modal :title="$t('point_of_sale_modal_delete')" id="delete-point-of-sale" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('point_of_sale_delete_confirmation') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="deletePointOfSale()" :disabled="event.isArchived" variant="danger">{{ $t('delete') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import EventNav from "@/components/EventNav";
import VueDraggable from 'vuedraggable'
import PosProduct from "@/components/PosProduct";
import StatusLabel from "@/components/StatusLabel";
import moment from "moment";
import {mapActions, mapState} from "vuex";
import {deepClone, validateUrl, validateSlug} from "@/functions";
import DateTimePicker from "@/components/DateTimePicker";
import QrCode from "@/components/QrCode.vue";

export default {
  components: {
    EventNav,
    VueDraggable,
    PosProduct,
    StatusLabel,
    DateTimePicker,
    QrCode
  },
  name: 'EventPointsOfSale',
  data() {
    return {
      has_donation_product: false,
      newPosProduct: {},
      saving: false,
      event: null,
      pointsOfSale: [],
      pos: null,
      company: null,
      products: [],
      deletes: [],
      selected_product_id: null,
      originalProducts: [],
      paymentMethods: [],
      productGroups: [],
      orgProductGroups: [],
      btn_save_disabled: false,
      originalPaymentMethods: [],
      languages: [{
        label: this.$t('lang_nl'),
        language: 'nl'
      }, {
        label: this.$t('lang_en'),
        language: 'en'
      }, {
        label: this.$t('lang_de'),
        language: 'de'
      }, {
        label: this.$t('lang_es'),
        language: 'es'
      }],
      posHasProducts: false,
      posHasPaymentMethods: false,
      showCountdown: false,
    }
  },
  created() {
    document.title = this.$t('backend_title_events_pos')
    if(this.$store.state.user) {
      if(!this.$can('event_point_of_sale_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.event().get(this.$route.params.id).then(event => {
      this.event = {...event,isArchived :( moment(event.ends_at).isBefore(moment()) && !event.continuous) || event.status === "archived"}
    })
    api.event().posDependencies().then(response => {
      this.company = response.company
      this.originalPaymentMethods = response.payment_methods.filter((paymentMethod) => {
        // Add the VAT percentage of where the company is based if applicable
        if(response.company.vat_percentage && paymentMethod.payment_method_fees && paymentMethod.payment_method_fees[0].fee_fixed_amount){
          paymentMethod.payment_method_fees[0].fee_fixed_amount += (response.company.vat_percentage/100) * paymentMethod.payment_method_fees[0].fee_fixed_amount
          paymentMethod.payment_method_fees[0].fee_fixed_amount = Number(paymentMethod.payment_method_fees[0].fee_fixed_amount).toFixed(2)
        }
        return paymentMethod
      })
    })
    
    api.product().get(this.$route.params.id).then(products => {
      this.originalProducts = products
    })

    api.product().getGroups(this.$route.params.id).then((productGroups => {
      this.productGroups = productGroups.filter((group) => {
        return group.products.length > 0
      }).map((group) => {
        group.open = true
        return group
      })
      this.orgProductGroups = deepClone(this.productGroups);
    }))

    this.get()

    this.loadProductTypeDonation()
  },
  methods: {
    newPos() {
      this.pos = {}
      this.$bvModal.show('new-pos')
    },
    get() {
      api.pos().get(this.$route.params.id).then(pointsOfSale => {
        this.pointsOfSale = pointsOfSale
      })
    },
    showDeleteModal(point_of_sale_id) {
      this.$bvModal.show('delete-point-of-sale')
      this.selected_point_of_sale_id = point_of_sale_id
    },
    deletePointOfSale() {
      this.$bvModal.hide('delete-point-of-sale')
      api.pos().delete(this.$route.params.id, this.selected_point_of_sale_id).then(() => {
        this.selected_point_of_sale_id = null
        this.get()
        this.$store.commit('success', 'point_of_sale_has_been_deleted')
      })
    },
    posStatusChange(pos){
      this.paymentMethods = this.paymentMethods.map(paymentMethod => {
        if(pos.status === 'box-office' && paymentMethod.show_pos){
          paymentMethod.online = false
        }
        if(pos.status !== 'box-office' && paymentMethod.show_box){
          paymentMethod.online = false
        }
        if(pos.status === 'box-office' && paymentMethod.show_box){
          paymentMethod.online = true
        }
        return paymentMethod
      })
    },
    edit(pos) {
      pos.use_all_stock = (pos.use_all_stock) ? true : false
      pos.show_in_overview = (pos.show_in_overview) ? true : false
      
      this.pos = Object.assign({}, pos)
      this.showCountdown = false;
      if(pos.countdown) {
        this.showCountdown = true;
      }

      this.productGroups = deepClone(this.orgProductGroups);

      // Load the pos stock for all the grouped products.
      this.productGroups = this.productGroups.map(productGroup => {

        // Map all the products and try to find their stock in the POS.
        productGroup.products = productGroup.products.map(product => {
          //check if pos has donation product this controls the visibility of the toggle btn
          if (product.product_type_id === this.donation_product_id) {
            this.has_donation_product = true;
          }
          if (!this.pos.products) {
            // New pos
            return product
          }
          let posProduct = this.pos.products.find(posProduct => {
            return posProduct.product_id === product.id
          })
          if (posProduct) {
            product.pos_stock = posProduct.stock
            product.sort = posProduct.sort
            product.is_enabled = true

            //toggle allow donation btn based on products in the pos
            if (product.product_type_id === this.donation_product_id) {
              this.pos.has_donation = this.has_donation_product;
            }
          } else {
            //no point of sale .. set default values
            product.pos_stock = 0;
            product.sort = 0;
            product.is_enabled = false;

          }
          return product
        })

        // Sort the products in the group
        productGroup.products.sort((a, b) => {
          return a.sort - b.sort
        })

        // Check if the product group has a sort within the point of sale
        if (!this.pos.pos_product_groups) {
          return productGroup
        }

        let posProductGroup = this.pos.pos_product_groups.find(posProductGroup => {
          return posProductGroup.product_group_id === productGroup.id
        })
        if (posProductGroup) {
          productGroup.sort = posProductGroup.sort
        }

        if(productGroup.sort === undefined){
          productGroup.sort = 999
        }

        return productGroup
      }).sort((a, b) => {
        // Sort all the product groups
        return a.sort - b.sort
      })

      this.paymentMethods = deepClone(this.originalPaymentMethods).map(paymentMethod => {

        // If there are not any payment methods yet, return the default
        if (!this.pos.payment_methods) {
          return paymentMethod
        }

        let posPayment = this.pos.payment_methods.find(posPaymentMethod => {
          return (posPaymentMethod.payment_method_id === paymentMethod.id && posPaymentMethod.online)
        })

        if (posPayment) {
          paymentMethod.online = true
          paymentMethod.fee_fixed = posPayment.fee_fixed
          paymentMethod.fee_percentage = posPayment.fee_percentage
          paymentMethod.sort = posPayment.sort
        } else {
          paymentMethod.sort = 1000
          paymentMethod.fee_fixed = 0
          paymentMethod.fee_percentage = 0
        }
        paymentMethod.err_fee_fixed = false
        paymentMethod.err_fee_percentage = false

        return paymentMethod
      }).sort((a, b) => {
        return a.sort - b.sort
      })

      setTimeout(() => {
          this.checkPosCanSave();
      }, 100)

      this.$bvModal.show('edit-pos')
    },
    save() {

      // Make te language mandatory
      if (!this.pos.language || (this.pos.language && this.pos.language == '')) {
        this.$store.commit('errors', [this.$t('pos_no_language')]);
        return;
      }

      if (this.pos.terms_conditions_url) {
        if (!validateUrl(this.pos.terms_conditions_url)) {
          this.$store.commit('errors', [this.$t('pos_invalid_url')]);
          return;
        }
      }

      if (this.pos.url) {
        if (!validateSlug(this.pos.url)) {
          this.$store.commit('errors', [this.$t('pos_invalid_url')]);
          return;
        }
      }

      this.pos.payment_methods = this.paymentMethods.map((paymentMethod) => {
        return {
          payment_method_id: paymentMethod.id,
          fee_percentage: paymentMethod.fee_percentage,
          fee_fixed: paymentMethod.fee_fixed,
          online: paymentMethod.online
        }
      })

      this.pos.groups = this.productGroups.map((productGroup) => {
        productGroup.products = productGroup.products.map((product) => {
          //check if donation is enabled, remove the donation product if disabled
          if (product.product_type_id === this.donation_product_id) {
            product.is_enabled = !(this.has_donation_product && !this.pos.has_donation);
            product.pos_stock = -1;
          }

          if (!product.is_enabled) {
            //set stock to zero , removes it from db
            product.pos_stock = 0;
          }
          return product;
        })
        return productGroup;
      })

      // Empty the countdown fields when the checkbox is unset
      if(!this.showCountdown){
        this.pos.countdown = null
      }

      // Disable show in overview when the POS is not live
      if(this.pos.status !== 'live'){
        this.pos.show_in_overview = false
      }
      
      api.pos().save(this.$route.params.id, this.pos).then(() => {
        this.get()
        this.$bvModal.hide('edit-pos')
        this.pos = null
        this.$store.commit('success', 'pos_updated')
      })
    },
    open(pos) {
      window.open(this.getPosUrl(pos), '_blank')
    },
    getPosUrl(pos) {
      let fullUrl = '';
      if(pos.status === 'box-office'){
          let routeData = this.$router.resolve({name: 'BoxOfficeLogin'});
          fullUrl = '//' + this.company.domain + routeData.href
      } else {
          fullUrl = '//' + this.company.domain + '/p/' + pos.url
      }
      return fullUrl;
    },
    create() {
      api.pos().create(this.$route.params.id, this.pos).then((pos) => {
        this.pointsOfSale.push(pos)
        if(this.$can('event_point_of_sale_edit')) {
          this.edit(pos);
        }
        this.$bvModal.hide('new-pos')
      })
    },
    validatePaymentInput() {
      this.btn_save_disabled = false

      for (let i = 0; i < this.paymentMethods.length; i++) {

        if (this.paymentMethods[i].psd2_calc_to_customer == 'no') {
          this.paymentMethods[i].fee_fixed = 0
          this.paymentMethods[i].fee_percentage = 0
        } else if (this.paymentMethods[i].psd2_calc_to_customer == 'fairtix-fee') {
          let el = document.getElementById("paymentMethodErrorFixed" + this.paymentMethods[i].id);
          if (this.paymentMethods[i].fee_fixed > this.paymentMethods[i].payment_method_fees[0].fee_fixed_amount) {
            el.classList.add('is-invalid')
            this.btn_save_disabled = true
          } else {
            el.classList.remove('is-invalid')
          }

          el = document.getElementById("paymentMethodErrorPercentage" + this.paymentMethods[i].id);
          if (this.paymentMethods[i].fee_percentage > this.paymentMethods[i].payment_method_fees[0].fee_percentage) {
            el.classList.add('is-invalid')
            this.btn_save_disabled = true
          } else {
            el.classList.remove('is-invalid')
          }
        }

      }
    },
    checkPosCanSave() {
      this.posHasProducts = this.productGroups.some(pg => {
        return pg.products.some(p => p.is_enabled && p.pos_stock !== 0 && p.pos_stock !== null && p.pos_stock !== '')
      })
      this.posHasPaymentMethods = this.paymentMethods.some(pm => pm.online);
    },
    ...mapActions(['loadProductTypeDonation'])
  },
  computed: {
    ...mapState(['donation_product_id']),
  },
  watch: {
    paymentMethods() {
        this.checkPosCanSave();
    },
    '$store.state.user': {
      handler() {
        if(!this.$can('event_point_of_sale_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.notice-message {
  font-size: 9pt
}

.hidden{
  display:none !important
}

.product-group-products .input-group-append > .input-group-text {
  min-width: 100px;
}

.product-group-row {
  align-items: center;
  padding: 20px 0 3px;
  border-bottom: solid 1px silver;
  h5{
    margin: 0;
  }
}

.product-group-products {
  padding-top: .7em;
}

.pos-product-groups {
  margin-bottom: 1em;
}

.handle {
  cursor: grab !important;
  margin-top: 0px
}




tbody button{
  border-color: #ddd!important;
}
tbody:hover button{
  background-color: unset !important;
  color: unset!important;
  border-color: #ddd!important;
}
tbody button:hover{
  background-color: #F0B52D !important;
  color: #fff!important;
  border-color: #F0B52D!important;
}
.desc{
  font-size: 13px;
}
@media (max-width: 1550px){
h1{
  font-size: 2.2rem;
}
}
@media (max-width:576px) {
  .Rowhead{
  width: 100%;
  background-color: #193E58;
  margin:10px  auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.Rowhead div{
  color: #fff!important;
  font-size: 13px;
}
.Rowbody{
  padding: 10px 0px;
  width: 100%;
  border: 1px solid #ddd;
  margin: auto;
  background: #F5F5F5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  
}
.Rowbody div{
  font-size: 13px;
}
.custom-control-label::before,.custom-control-label::after{
  top: 0rem;
}
.product-group-row {
  h5{
    font-size: 18px;
  }
}
}
</style>
<style scoped>
/deep/ .pos-table td:first-child {
    text-align: center;
    width: 60px;
}
</style>
<style>
@media (max-width:576px) {
#edit-pos .Rowbody  .custom-control-label::before,#edit-pos .Rowbody  .custom-control-label::after{
  top: 0rem!important;
}
#edit-pos{
  padding: 0!important;
}
}

.event-poses .qr-show .btn.dropdown-toggle {
    background-color: #FFF;
    border-radius: 6px;
    border: 2px solid #ddd;
    color: #808080;
    padding: 5px 20px;
    font-size: 13px;
    font-weight: bold;
}

.event-poses .qr-show .btn.dropdown-toggle::after {
    margin-bottom: -2px !important;
}

.event-poses tbody:hover button {
    background-color: unset !important;
    color: unset !important;
    border-color: #ddd !important;
}

.event-poses tbody button:hover {
    background-color: #F0B52D !important;
    color: #fff !important;
    border-color: #F0B52D !important;
}

.event-poses .qr-show-container .b-dropdown-text {
    text-align: center;
    padding: 0.5rem !important;
    width: 300px;
}

.event-poses .qr-show-container .b-dropdown-text img {
    margin: auto;
}
</style>
