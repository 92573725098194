<template>

  <div>

    <b-row class="Parent">
      <b-col cols="12" lg="2">
        <b-form-group v-if="$can('order_overview_search')">
          <b-form-input class="v-text-field" v-model="search" debounce="500"
                        :placeholder="$t('search')" @update="getOrders(1, $event)"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <b-form-group class="order-events">
          <b-dropdown id="dropdown-Checkbox" :text="selectedEvents.length ? (selectedEvents.length + ' ' + $t('permission_events_selected')) : $t('permission_all_events')" ref="dropdown" class="w-100">
            <b-form-group class="select-events-container">
              <b-form-input v-model="searchEvents" :placeholder="$t('search')" size="sm" class="mb-2"></b-form-input>
              <b-form-checkbox v-model="showArchivedEvents" switch>{{ $t('event_show_archived') }}</b-form-checkbox>
              <hr>
              <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="selectedEvents"
                  name="flavour-2"
              >
                <b-form-checkbox v-for="ev in events.filter(e => showArchivedEvents ? true : e.status !== 'archived').filter(e => searchEvents ? e.name.toLowerCase().includes(searchEvents.toLowerCase()) : true)"
                                 :key="ev.id"
                                 :value="ev.id" class="w-100">{{ ev.name }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-dropdown>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <div class="d-inline-block mr-5">
          <div class="float-left">{{ $t('order_overview_show_expired') }}</div>
          <b-form-checkbox class="float-left ml-1" switch v-model="show_expired" :value="true" :unchecked-value="false" @change="getOrders()"></b-form-checkbox>
        </div>
        <div class="d-inline-block">
          <div class="float-left">{{ $t('order_overview_show_box_office_orders') }}</div>
          <b-form-checkbox class="float-left ml-1" switch v-model="show_box_office" :value="true" :unchecked-value="false" @change="getOrders()"></b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="12" sm="4" lg="2" v-if="this.$route.query.discountcode && this.$route.query.discountcode > 0">
        <b-button variant="warning" @click="removeDiscountcodeFilter()">{{ $t('order_overview_remove_discountcode_filter') }}</b-button>
      </b-col>
      <b-col cols="12" sm="6" lg="2" class="elem mt-3">
        <b-form-group v-if="$can('order_overview_export')" class="text-center ">
          <b-button v-b-modal.export-orders>{{ $t('order_export_generate_export') }}</b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal hide-footer id="export-orders" @close="exportSettings = {}" size="export-l" :title="$t('order_export_export')">
      <h5 class="export-title mt-3">{{ $t('order_export_type') }}</h5>
      <div>
        <b-button @click="changeExportType('visitor_list')"
        :class="{'btn-outline': exportSettings.order_type !== 'visitor_list', 'btn-primary': exportSettings.order_type === 'visitor_list'}"
        class="mt-2 mr-3">
        {{ $t('order_export_visitor_list') }}
        </b-button>

        <b-button @click="changeExportType('general')"
        :class="{'btn-outline': exportSettings.order_type !== 'general', 'btn-primary': exportSettings.order_type === 'general'}"
        class="mt-2 mr-3">
        {{ $t('order_export_general') }}
        </b-button>
      </div>

      <div class="mt-4">
        <h5 class="export-title">{{ $t('order_export_which_event') }}</h5>
        <div>
          <b-dropdown id="dropdown-Checkbox" :text="exportSettings.events.length ? (exportSettings.events.length + ' ' + $t('permission_events_selected')) : $t('permission_all_events')" ref="dropdown" class="w-100">
            <b-form-group class="allowed-events-container">
              <b-form-input v-model="exportSettings.searchEvents" :placeholder="$t('search')" size="sm" class="mb-2"></b-form-input>
              <b-form-checkbox-group
                  class="events-dropdown-inner"
                  id="checkbox-group-2"
                  v-model="exportSettings.events"
                  name="flavour-2"
                  @change="eventSelected"
              >
                <b-form-checkbox v-for="ev in events.filter(e => exportSettings.searchEvents ? e.name.toLowerCase().includes(exportSettings.searchEvents.toLowerCase()) : true)"
                                 :key="ev.id"
                                 :value="ev.id" class="w-100">{{ ev.name }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-dropdown>
        </div>
      </div>

      <div class="mt-4" v-if="exportSettings.order_type === 'visitor_list' && productsGroups.length">
        <h5 class="export-title">{{ $t('order_export_which_tickets') }}</h5>
        <b-form-group>
          <b-dropdown id="dropdown-Checkbox" ref="dropdown" :text="exportSettings.products.length === 0 ? $t('order_export_all_tickets') : $t('order_export_selected_tickets', {count: exportSettings.products.length})" class="w-100">
            <b-form-group class="allowed-events-container">
              <b-form-input v-model="exportSettings.searchProducts" :placeholder="$t('search')" size="sm" class="mb-2"></b-form-input>
              <b-form-checkbox-group
                  class="events-dropdown-inner"
                  id="checkbox-group-2"
                  v-model="exportSettings.products"
                  name="flavour-2"
              >
                <div v-for="group in productsGroups" :key="group.name">
                  <label class="group-label d-block mt-3 mb-0" v-if="group.name !== 'event_default_group'">{{ group.name }}</label>
                  <b-form-checkbox v-for="product in group.products"
                                   :key="product.id"
                                   :value="product.id"
                                   :class="{'ml-2': group.name !== 'event_default_group'}"
                                   class="w-100">{{ product.name }}</b-form-checkbox>
                </div>
              </b-form-checkbox-group>
            </b-form-group>
          </b-dropdown>
        </b-form-group>
      </div>

      <div v-if="exportSettings.order_type === 'general'" class="mt-4">
        <h5 class="export-title">{{ $t('order_export_period') }}</h5>
        <b-row>
          <b-col>
            <date-picker v-model="exportSettings.from" :state="exportSettings.validations.from.state" aria-describedby="date-from"></date-picker>
            <b-form-invalid-feedback v-if="exportSettings.validations.from.state === false" id="date-from" class="d-block">
              {{ $t(exportSettings.validations.from.message) }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col>
            <date-picker v-model="exportSettings.to" :state="exportSettings.validations.to.state" aria-describedby="date-to"></date-picker>
            <b-form-invalid-feedback v-if="exportSettings.validations.to.state === false" id="date-to" class="d-block">
              {{ $t(exportSettings.validations.to.message) }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </div>

      <div class="mt-4">
        <h5 class="export-title">{{ $t('order_export_which_email') }}</h5>
        <b-form-input v-model="exportSettings.email" debounce="750" :state="exportSettings.validations.email.state" aria-describedby="email" :placeholder="$t('Email') + '*'"></b-form-input>
        <b-form-invalid-feedback v-if="exportSettings.validations.email.state === false" id="email" class="d-block">
          {{ $t(exportSettings.validations.email.message) }}
        </b-form-invalid-feedback>
      </div>

      <div v-if="exportSettings.order_type === 'general'" class="mt-4">
        <div>
          <h5 class="float-left export-title">{{ $t('order_export_which_fields') }}</h5>
          <b-button class="float-right text-white" variant="warning" @click="exportCheckAll()">{{ $t('export_settings_check_all') }}</b-button>
        </div>

        <b-form-group>
          <b-form-checkbox-group v-model="exportSettings.fields" :options="exportFields" :stacked="true">
          </b-form-checkbox-group>
        </b-form-group>
      </div>
      <b-form-group class="mt-4">
        <b-button class="btn-primary" :disabled="exportSettings.loading" @click="makeExport()">
          <b-icon v-if="exportSettings.loading" class="mr-2" icon="three-dots" animation="cylon"></b-icon> {{ $t('orders_export_make') }}
        </b-button>
      </b-form-group>
    </b-modal>
    <b-modal id="export-orders-success" hide-footer hide-header size="export-l">
      <div class="py-5">
        <div class="py-4 text-center">
          <b-icon icon="check-circle" font-scale="10" style="color:#529a47"></b-icon>
        </div>
        <div class="text-center px-5">
          <h3 class="my-3 font-weight-bold text-black">{{ $t('order_export_success') }}</h3>
          <span class="mt-4 export-success-description">{{ $t('order_export_success_message', {email: exportSettings.email}) }}</span>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col v-if="orders.length" class="tableBig">
        <b-table class="orderTable" :fields="fields" :items="orders">
          <template v-slot:cell(name)="{item}" >
            <span v-if="item.customer" >{{ item.customer.first_name }} {{ item.customer.last_name }}</span>
            <span v-else>{{ $t('unknown') }}</span>
          </template>
          <template v-slot:cell(created_at)="{item}">
            {{ item.created_at | date_time }}
          </template>
          <template v-slot:cell(event)="{item}">
            {{ item.event_name }}
          </template>
          <template v-slot:cell(number_of_products)="{item}">
            {{ item.order_products.length }}
          </template>
          <template v-slot:cell(order_type)="{item}">
            <b-badge variant="secondary" v-if="item.order_type === 'box-office'">
              {{ $t('order_overview_box_office') }}
            </b-badge>
            <b-badge v-else-if="item.order_type === 'api'">
              {{ $t('order_overview_api_order') }}
            </b-badge>
            <b-badge v-else-if="item.order_type === 'free-order'">
              {{ $t('order_overview_free_tickets') }}
            </b-badge>
            <b-badge v-else-if="item.order_type === 'manual-order'">
              {{ $t('order_overview_manual_orders') }}
            </b-badge>
            <b-badge v-else-if="item.order_type === 'batch-ticket'">
              {{ $t('order_overview_batch_ticket') }}
            </b-badge>
            <b-badge v-else-if="item.order_type === 'payment-link'">
              {{ $t('order_overview_payment_link') }}
            </b-badge>
            <b-badge v-else>
              {{ $t('order_overview_online') }}
            </b-badge>
          </template>
          <template v-slot:cell(is_paid)="{item}">
            <b-badge variant="secondary" v-if="item.payment_status === 'test_order_paid'">
              {{ $t('order_test_paid') }}
            </b-badge>
            <b-badge :variant="item.is_paid ? 'success' : 'danger'" v-else-if="!item.is_refunded && item.payment_status !== 'test_order_paid'">
              {{ item.is_paid ? $t('order_paid') : $t('order_not_paid') }}
            </b-badge>
            <b-badge variant="warning" v-else>
              {{ $t('order_is_refunded') }}
            </b-badge>
          </template>
          <template v-slot:cell(actions)="{item}">
            <b-button v-if="$can('order_detail_view')" variant="row-edit"
                      :to="{name: 'OrderDetails', params:{id: item.id, order_number: item.order_number}}">
              {{ $t('order_details') }}
            </b-button>
          </template>
        </b-table>
        <pagination v-if="page_data" :limit="3" :data="page_data" @pagination-change-page="getOrders($event, search)"></pagination>
      </b-col>
      <b-col v-else>
        <b-alert show variant="info">{{ $t('no_results_have_been_found') }}</b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../api'
import DatePicker from "@/components/DatePicker";
import moment from 'moment'
import {deepClone, validateEmail} from "../functions";
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      search: '',
      orders: [],
      show_expired: false,
      show_box_office: false,
      page_data: null,
      page: 1,
      timeout: null,
      exportFields: [

        // EVENT
        {text: this.$t('export_event_name'), value: 'event_name'},

        // ORDER
        {text: this.$t('export_order_number'), value: 'order_number'},
        {text: this.$t('export_total_price'), value: 'total_price'},
        {text: this.$t('export_discount'), value: 'discount'},
        {text: this.$t('export_order_payment_fee'), value: 'order_payment_fee'},
        {text: this.$t('export_order_payment_fee_vat'), value: 'order_payment_fee_vat'},
        {text: this.$t('export_order_payment_fee_vat_perc'), value: 'order_payment_fee_vat_perc'},
        {text: this.$t('export_ticket_fee_total'), value: 'order_ticket_fee'},
        {text: this.$t('export_ticket_fee_vat'), value: 'order_ticket_fee_vat'},
        {text: this.$t('export_ticket_fee_vat_perc'), value: 'order_ticket_fee_vat_perc'},

        // PRODUCT
        {text: this.$t('export_product_number'), value: 'order_product_number'},
        {text: this.$t('export_product_price'), value: 'order_product_price'},
        {text: this.$t('export_product_price_vat'), value: 'order_product_vat'},
        {text: this.$t('export_product_price_vat_perc'), value: 'order_product_vat_perc'},
        {text: this.$t('export_product_service_fee'), value: 'order_product_service_fee'},
        {text: this.$t('export_product_service_fee_vat'), value: 'order_product_service_fee_vat'},
        {text: this.$t('export_product_service_fee_vat_perc'), value: 'order_product_service_fee_vat_perc'},

        // ORDER
        {text: this.$t('export_order_payment_method'), value: 'payment_method'},
        {text: this.$t('export_order_timeslot'), value: 'timeslot'},

        // CUSTOMER
        {text: this.$t('export_customer_first_name'), value: 'customer_first_name'},
        {text: this.$t('export_customer_last_name'), value: 'customer_last_name'},
        {text: this.$t('export_customer_company_name'), value: 'customer_company_name'},
        {text: this.$t('export_customer_email'), value: 'customer_email'},
        {text: this.$t('export_customer_address'), value: 'customer_address'},
        {text: this.$t('export_customer_zip'), value: 'customer_address_zip'},
        {text: this.$t('export_customer_city'), value: 'customer_address_city'},
        {text: this.$t('export_customer_country'), value: 'customer_address_country'},
        {text: this.$t('export_customer_gender'), value: 'customer_gender'},
        {text: this.$t('export_customer_optin'), value: 'customer_optin'},
        {text: this.$t('export_customer_phone'), value: 'customer_phone'},
        {text: this.$t('export_customer_dob'), value: 'customer_dob'},

        // PRODUCT
        {text: this.$t('export_product_id'), value: 'order_product_id'},
        {text: this.$t('export_product_group'), value: 'product_group'},
        {text: this.$t('export_product_name'), value: 'product_name'},
        {text: this.$t('export_product_type'), value: 'product_type'},

        // TICKET
        {text: this.$t('export_product_ticket_value'), value: 'product_ticket_value'},

      ],
      exportSettings: {
        from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        email: null,
        fields: [],
        selected_events: [],
        order_type: 'visitor_list',
        events: [],
        searchEvents: null,
        event: null,
        groups: [],
        products: [],
        searchProducts: null,
        validations: {
          from: {
            state: null,
            message: null
          },
          event: {
            state: null,
            message: null
          },
          to: {
            state: null,
            message: null
          },
          email: {
            state: null,
            message: null
          }
        },
        loading: false
      },
      fields: [
        {key: 'order_type', label: this.$t('order_type'), sortable: true},
        {key: 'order_number', label: this.$t('order_number'), sortable: true},
        {key: 'name', label: this.$t('order_full_name'), sortable: true},
        {key: 'event', label: this.$t('order_event'), sortable: true},
        {key: 'number_of_products', label: this.$t('order_number_of_products'), sortable: true},
        {key: 'created_at', label: this.$t('order_created_at'), sortable: true},
        {key: 'is_paid', label: this.$t('order_is_paid')},
        {key: 'actions', label: ''},
      ],
      events: [],
      selectedEvents: [],
      searchEvents: '',
      showArchivedEvents: false,
    }
  },
  watch: {
    selectedEvents() {
      this.getOrders();
    },
    '$store.state.user': {
      handler() {
        if(!this.$can('order_overview_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
        this.exportSettings.email = this.$store.state.user.email;
      }
    },
    '$store.state.language': {
      handler() {
        this.fields = [
          {key: 'order_type', label: this.$t('order_type'), sortable: true},
          {key: 'order_number', label: this.$t('order_number'), sortable: true},
          {key: 'name', label: this.$t('order_full_name'), sortable: true},
          {key: 'event', label: this.$t('order_event'), sortable: true},
          {key: 'created_at', label: this.$t('order_created_at'), sortable: true},
          {key: 'is_paid', label: this.$t('order_is_paid')},
          {key: 'actions', label: ''}
        ];
      }
    }
  },
  methods: {
    removeDiscountcodeFilter(){
      this.$router.replace('?')
      this.getOrders()
    },
    makeExport() {
      this.exportSettings.validations.from.state = null;
      this.exportSettings.validations.from.message = null;
      this.exportSettings.validations.to.state = null;
      this.exportSettings.validations.to.message = null;
      this.exportSettings.validations.email.state = null;
      this.exportSettings.validations.email.message = null;
      this.exportSettings.validations.event.state = null;
      this.exportSettings.validations.event.message = null;

      if(this.exportSettings.order_type === 'general') {
        if(!this.exportSettings.from) {
          this.exportSettings.validations.from.state = false;
          this.exportSettings.validations.from.message = 'field_is_required';
        }
        if(!this.exportSettings.to) {
          this.exportSettings.validations.to.state = false;
          this.exportSettings.validations.to.message = 'field_is_required';
        }
        if(!this.exportSettings.from || !this.exportSettings.to) {
          return;
        }

        if(this.exportSettings.from > this.exportSettings.to) {
          this.exportSettings.validations.to.state = false;
          this.exportSettings.validations.from.state = false;
          this.exportSettings.validations.to.message = 'end_date_must_be_greater';
          return;
        }
      }

      if(!this.exportSettings.email || !this.exportSettings.email.length) {
        this.exportSettings.validations.email.state = false;
        this.exportSettings.validations.email.message = 'field_is_required';
        return;
      }

      if(!validateEmail(this.exportSettings.email)) {
        this.exportSettings.validations.email.state = false;
        this.exportSettings.validations.email.message = 'wrong_email';
        return;
      }

      this.exportSettings.loading = true;

      api.order().makeExport(this.exportSettings).then(() => {
        this.$bvModal.hide('export-orders')
        this.$bvModal.show('export-orders-success');
      }).finally(() => {
        this.exportSettings.loading = false;
      })
    },
    exportCheckAll() {
      this.exportSettings.fields = this.exportFields.map((f) => {
        return f.value
      })
    },
    getOrders(page = 1, search) {
      if (!page) {
        page = 1
      }
      api.order().getAll(page, this.show_expired, this.show_box_office, this.selectedEvents, this.$route.query.discountcode, search).then((orders) => {
        this.page_data = orders
        this.orders = orders.data.map(order => {
          return Object.assign(order, {is_refunded: order.order_products.filter(p => p.refunded_at === null).length === 0})
        })
      })
    },
    eventSelected(events) {
      if(!events.length) {
        return;
      }
      if(this.exportSettings.order_type === 'general') {
        if(events.length === 1) {
          const event = this.events.find(e => e.id === events[0]);
          this.exportSettings.from = moment(event.created_at).format('YYYY-MM-DD');
          this.exportSettings.to = moment().format('YYYY-MM-DD');
        }
        else {
          this.exportSettings.from = moment().subtract(7, 'days').format('YYYY-MM-DD');
          this.exportSettings.to = moment().format('YYYY-MM-DD');
          return;
        }
      }
      let event = null;
      if(events.length === 1) {
        event = events[0];
      }
      else {
        event = events[events.length - 1]
      }
      this.exportSettings.events = [event];
      this.exportSettings.groups = [];
      this.exportSettings.products = [];
      if(!event) {
        return;
      }

      api.guestList().getProductsByEvent(event).then(response => {
        this.exportSettings.groups = response;
      })
    },
    changeExportType(type) {
      this.exportSettings.events = [];
      this.exportSettings.event = null;
      this.exportSettings.order_type = type;
    }
  },
  created() {
    document.title = this.$t('backend_title_order_overview')
    if(this.$store.state.user) {
      if(!this.$can('order_overview_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
      this.exportSettings.email = this.$store.state.user.email;
    }
    api.event().all(true).then(events => {
      this.events = events;
    })
    this.getOrders()
  },
  computed: {
    productsGroups() {
      if(!this.exportSettings.events.length) {
        return [];
      }

      return deepClone(this.exportSettings.groups).map(group => {
        group.products = group.products.filter(p => {
          if(this.exportSettings.searchProducts === null || !this.exportSettings.searchProducts.length) {
            return true;
          }
          return p.name.toLowerCase().includes(this.exportSettings.searchProducts.toLowerCase())
        })
        return group;
      }).filter(group => {
        return group.products.length
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.elem{
  margin: auto;
}
.Parent > div{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.Parent{
  margin-bottom: 20px;
}
.float-left{
  float:left
}
fieldset{
  width: 100%!important;
  margin: 0px;
}
.select-events-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height:250px;
}
.btn-primary, .btn-primary:active, .btn-primary:focus {
  background-color: #193e58;
  border: 0 !important;
  box-shadow: unset !important;
  &:hover {
    background-color: #102636;
  }
}

.btn-outline {
  background-color: #FFFFFF !important;
  border: 1px solid #6c757d !important;
  color: #6c757d !important;

  &:hover {
    background-color: #6c757d !important;
    color: #FFFFFF !important;
  }
}
.group-label {
  background-color: #EEEEEE;
  color: #000!important;
  font-size: 15px!important;
  padding: 5px ;
}

.text-black {
  color: #000000 !important;
}

.export-success-description {
  font-size: larger;
}

.export-title {
  color: #9d9d9d !important;
}

input::placeholder, select::placeholder {
  color: #b3b3b3;
}

.events-dropdown-inner {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>

<style lang="scss">
@media (min-width: 992px) {
  .modal-export-l {
    max-width: 660px !important;
  }
}
.order-events>div {
  height: 50px !important;
}
.v-select.invalid .vs__dropdown-toggle{
  border: 2px solid #c2464c !important;
}
</style>
