<template>
  <div v-if="event">
    <div>
      <b-alert v-if="this.event.isArchived" variant="danger" show class="text-center">
        {{ $t('event_is_archived') }}
      </b-alert>
      <div  class="d-flex  flex-wrap">
        <div class="bigDiv px-0 pr-lg-3 pl-lg-0 order-2 order-lg-1 mb-3">
          <b-button v-if="$can('event_products_create')" class="mb-3 float-right px-4 py-3" variant="new" :disabled="event.isArchived" @click="newProduct()">
            {{ $t('event_product_add') }}
          </b-button>
          <div v-else class="mb-3 float-right px-4 py-3" style="height: 56px;"></div>
          <div v-if="no_products">
            <table class="table b-table" style="width:100%;">
              <thead>
                <tr>
                  <th style="width: 25%; color:white;">{{ $t('product_status') }}</th>
                  <th style="width: 25%; color:white;">{{ $t('product_name') }}</th>
                  <th style="width: 25%; color:white;">{{ $t('product_price') }}</th>
                  <th style="width: 25%; color:white;">{{ $t('product_stock') }}</th>
                  <th style="width: 25%; color:white;">{{ $t('actions') }}</th>
                </tr>
              </thead>
            </table>
            <h4>{{ $t('product_no_products') }}</h4>
          </div>
          <div v-for="productGroup in productGroups" :key="productGroup.name">
            <h2>{{ productGroup.type == 'default' ? $t('no_product_group') : productGroup.name }}</h2>
            <div class="tableBig">
            <table class="table b-table" style="width:100%;">
              <thead>
                <tr  class="responsive">
                  <th>{{ $t('product_status') }}</th>
                  <th>{{ $t('product_name') }}</th>
                  <th>{{ $t('product_price') }}</th>
                  <th>{{ $t('product_stock') }}</th>
                  <th>{{ $t('actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in productGroup.products" :key="`product` + product.id">
                  <td>
                    <status-label :status="product.status"></status-label>
                  </td>
                  <td>{{ product.name }}</td>
                  <td>{{ product.price | currency }}</td>
                  <td>{{ parseInt(product.stock) === -1 ? $t('product_stock_unlimited_text') : product.stock  }}</td>
                  <td class="flexing">
                    <b-button v-if="$can('event_products_edit')" class="manage-btn" variant="row-edit" @click="editProduct(product)">{{ $t('manage') }}</b-button>
                    <a v-if="$can('event_products_delete')" class="delete-btn" @click="showDeleteModal(product.id)">
                      <b-img :src="require('./../assets/icons/trash.png')"/>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
        <div class="SmallDiv px-0 pl-lg-3 pr-lg-0 order-1 order-lg-2 mb-4 mb-lg-1">
          <event-nav v-if="event && event.name" :event="event"></event-nav>
        </div>
      </div>
    </div>
    <b-modal :title="$t('product_modal_edit')" id="edit-product" size="lg">
      <div v-if="product">
        <div class="text-right">
          <span id="gear-popover" class="cursor-pointer"><b-icon icon="gear-fill" scale="1.3"></b-icon> <span class="font-14">{{ $t('product_more_items') }}</span></span>
          <b-tooltip target="gear-popover" triggers="hover" :delay="{ show: 300, hide: 50 }">
            {{ $t('product_show_hide_input_fields') }}
          </b-tooltip>
          <b-popover
            target="gear-popover"
            placement="bottomleft"
            triggers="click"
          >
            <b-form-group :label="$t('product_show_hide_input_fields')">
              <b-form-checkbox v-model="inputVisibilitySettings.generateETicket">{{ $t('product_create_eticket') }}</b-form-checkbox>
              <b-form-checkbox v-model="inputVisibilitySettings.ticketTemplate">{{ $t('event_ticket_template') }}</b-form-checkbox>
              <b-form-checkbox v-model="inputVisibilitySettings.scanColor">{{ $t('product_scan_color') }}</b-form-checkbox>
              <b-form-checkbox v-model="inputVisibilitySettings.productImage">{{ $t('product_image') }}</b-form-checkbox>
              <b-form-checkbox v-model="inputVisibilitySettings.minMaxPerOrder">{{ $t('product_min_max_buy_per_product') }}</b-form-checkbox>
              <b-form-checkbox v-model="inputVisibilitySettings.belongToProduct">{{ $t('product_show_when_product_chosen') }}</b-form-checkbox>
              <b-form-checkbox v-model="inputVisibilitySettings.salesSets">{{ $t('product_sell_product_sets') }}</b-form-checkbox>
              <b-form-checkbox v-model="inputVisibilitySettings.sendAdditionalTicket">{{ $t('product_send_additional_ticket') }}</b-form-checkbox>
              <b-form-checkbox v-model="inputVisibilitySettings.scanTillDateTime">{{ $t('product_scan_till_date_time') }}</b-form-checkbox>
              <b-form-checkbox v-if="$can('product_ordered_email_confirmation')" v-model="inputVisibilitySettings.sendConfirmationEmail">{{ $t('event_product_send_email') }}</b-form-checkbox>
              <b-form-checkbox v-model="inputVisibilitySettings.limitAmountToMaximumTickets">{{ $t('product_limit_amount_to_maximum_tickets') }}</b-form-checkbox>
            </b-form-group>
          </b-popover>
        </div>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group :label="$t('product_name')">
              <b-form-input v-model="product.name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group :label="$t('product_group')">
              <b-form-input list="groups" v-model="product.group_name"></b-form-input>
              <datalist id="groups">
                <option v-for="group in productGroups" :key="`grp` + group.id" :value="group.name"></option>
              </datalist>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group :label="$t('product_type')">
              <b-select
                  v-model="product.product_type_id"
                  text-field="name"
                  v-on:change="handleProductTypeChange"
                  value-field="id"
                  :options="productTypes"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group :label="$t('product_status')">
              <b-select v-model="product.status" :options="['draft', 'live', 'sold-out', 'archived']"></b-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="product.product_type_id !== donation_product_id">
          <b-col cols="12" lg="6">
            <b-form-group>
              <b-form-checkbox v-on:change="onSpecificSale" v-model="product.specific_sales_time">
                {{ $t('product_specific_sale_times') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6" v-if="product.product_type_id !== donation_product_id">
            <b-form-group>
              <b-form-checkbox v-model="product.is_multi_day_ticket" >
                {{ $t('product_multi_day_ticket') }} <b-icon id="is-multi-day-ticket-tooltip" v-b-tooltip.hover class="ml-2" icon="info-circle-fill"></b-icon>
              </b-form-checkbox>
              <b-tooltip target="is-multi-day-ticket-tooltip" triggers="hover">
                {{ $t("product_multi_day_ticket_tooltip") }}
              </b-tooltip>
            </b-form-group>
          </b-col>
        </b-row>
          <b-row>
          <b-col cols="12" lg="6" v-if="inputVisibilitySettings.generateETicket">
            <b-form-group>
              <b-form-checkbox v-model="product.create_eticket">
                {{ $t('product_create_eticket') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          </b-row>
        <b-row>
          <b-col v-if="product.specific_sales_time" cols="12" lg="6">
            <b-form-group>
              <b-form-checkbox v-model="product.sale_show_after_expire">
                {{ $t('product_show_after_expire') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="product.specific_sales_time">
          <b-col cols="12" >
            <date-time-picker v-model="product.sale_starts_at" :label="$t('product_sale_starts_at')"></date-time-picker>
            <date-time-picker v-model="product.sale_ends_at" :label="$t('product_sale_ends_at')"></date-time-picker>
          </b-col>
        </b-row>
        <b-row v-if="product.product_type_id !== donation_product_id">
          <b-col cols="12"  lg="6">
            <b-form-group>
              <b-form-checkbox v-model="product.is_cross_sell" >
                {{ $t('product_is_cross_sell') }}
              </b-form-checkbox>
            </b-form-group>

          </b-col>
          <b-col cols="12"  lg="6">
            <b-form-group>
              <b-form-checkbox v-model="product.calculate_service_fee" >
                {{ $t('product_calculate_service_fee') }}
              </b-form-checkbox>
              <b-input-group v-if="product.calculate_service_fee" class="mt-2">
                <template #prepend>
                  <b-input-group-text>{{ $t('currency') }}</b-input-group-text>
                </template>
                <b-form-input type="number" v-model="product.service_fee" :step="0.01" :min="0"></b-form-input>
              </b-input-group>
              <i class="hint-text mt-2" v-if="product.calculate_service_fee && ticket_fee_incl > 0">{{$t('product_companies_ticket_fee', { ticket_fee_incl: this.ticket_fee_incl})}}</i>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="product.product_type_id !== donation_product_id">
          <b-col cols="12" lg="6">
            <b-form-group :label="$t('product_stock')">
              <b-input-group>
                <b-form-input type="number" v-model="product.stock" :disabled="parseInt(product.stock) === -1" ></b-form-input>
                  <template #append>
                    <b-input-group-text>
                      <b-form-checkbox v-model="product.stock" value="-1" unchecked-value="0" >
                        {{ $t('product_stock_unlimited_text') }}
                      </b-form-checkbox>
                    </b-input-group-text>
                </template>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-row>
              <b-col  cols="12" lg="6" v-if="product.product_type_id !== donation_product_id">
                <b-form-group :label="$t('product_price_inclusive_vat')">
                  <b-form-input type="number" v-model="product.price" step="0.01"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6">
                <b-form-group :label="$t('product_vat_percentage')">
                  <b-form-select v-model="product.vat_percentage" :options="vats"></b-form-select>
                  <b-form-input  v-if="product.vat_percentage === 'different'"
                                 type="number"
                                 v-model="vat"
                                 step="0.01"
                                 :max="100"
                                 :min="0"
                                 class="mt-1"
                                 @input="validateVat"
                                 @change="validateVat"
                                 :state="state.vat"
                                 aria-describedby="event-vat"
                                 :placeholder="$t('product_vat_percentage')"></b-form-input>
                  <b-form-invalid-feedback v-if="state.vat === false" id="event-vat-">
                    {{ $t('event_vat_is_required') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="inputVisibilitySettings.ticketTemplate" cols="12" lg="6">
            <b-form-group>
              <label class="text-black">{{ $t('event_ticket_template') }} <b-icon id="event-ticket-template-tooltip" v-b-tooltip.hover class="ml-2" icon="info-circle-fill"></b-icon></label>
              <b-select v-model="product.ticket_template_id" text-field="name" value-field="id" :options="templates"></b-select>
              <b-tooltip target="event-ticket-template-tooltip" triggers="hover">
                {{ $t("event_ticket_template_product_tooltip") }}
              </b-tooltip>
            </b-form-group>
          </b-col>
          <b-col v-if="inputVisibilitySettings.minMaxPerOrder" cols="12" lg="6">
            <b-row>
              <b-col>
                <b-form-group :label="$t('product_min_buy')">
                  <b-form-input type="number" :max="product.max_buy" v-model="product.min_buy"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('product_max_buy')">
                  <b-form-input type="number" :min="product.min_buy" v-model="product.max_buy"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-if="inputVisibilitySettings.scanTillDateTime" md="12" sm="12">
          <b-col>
            <date-time-picker v-model="product.scan_till_date_time" :label="$t('product_scan_till_date_time')"></date-time-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="inputVisibilitySettings.sendAdditionalTicket" md="6" sm="12">
            <b-form-group>
              <b-form-checkbox v-model="isSendAdditionalTicket">
                {{ $t('product_send_additional_ticket_ordered') }} <b-icon id="addition-tooltip" v-b-tooltip.hover class="ml-2" icon="info-circle-fill"></b-icon>
              </b-form-checkbox>
              <b-tooltip target="addition-tooltip" triggers="hover" :delay="{ show: 300, hide: 50 }">
                {{ $t('product_additional_ticket_description') }}
              </b-tooltip>
            </b-form-group>
            <b-form-group v-if="isSendAdditionalTicket && product"  :label="$t('event_manage_products')" class="mt-4">
              <b-dropdown id="dropdown-Checkbox" ref="dropdown" class="w-100" :text="additionalTicketsSelectedProducts.length ? (additionalTicketsSelectedProducts.length + ' ' + $t('event_products_selected')) : $t('event_products_no_selected')">
                <b-form-group class="allowed-events-container">
                  <b-form-checkbox-group v-model="additionalTicketsSelectedProducts" id="checkbox-group-2" name="flavour-2">
                    <b-form-checkbox
                      v-for="pr in products.filter(p => p.id !== product.id)"
                      :key="pr.id"
                      :value="pr.id" class="w-100">{{ pr.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="inputVisibilitySettings.salesSets" sm="12" lg="6">
            <b-form-group :label="$t('product_sell_product_sets')">
              <b-form-input type="number" :min="product.min_buy ? product.min_buy : 0" :max="product.max_buy ? product.max_buy : 9999"  v-model="product.sale_sets"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="$can('product_ordered_email_confirmation') && inputVisibilitySettings.sendConfirmationEmail" sm="12" lg="6">
            <b-form-group :label="$t('event_product_send_email')">
              <b-form-input
                type="email"
                :state="state.email"
                @input="validateEmail($event)"
                aria-describedby="input-f-name-feedback"
                v-model="product.confirmation_email"></b-form-input>
              <b-form-invalid-feedback  v-if="state.email === false" id="input-email-feedback">{{ $t('email_is_invalid') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('product_description')">
              <b-textarea v-model="product.description" rows="6"></b-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="inputVisibilitySettings.scanColor" cols="12" lg="6">
            <b-tooltip target="product-scan-color-tooltip" triggers="hover">
              {{ $t("product_scan_color_explenation") }}
            </b-tooltip>
            <b-form-group>
              <label class="text-black">{{ $t('product_scan_color') }}<b-icon id="product-scan-color-tooltip" v-b-tooltip.hover class="ml-2" icon="info-circle-fill"></b-icon></label>
              <br>
              <color-picker v-model="product.scan_color"></color-picker>
              <a @click="removeScanColor" href="javascript:" class="d-block font-16 mt-3">
                {{ $t('product_remove_scan_color') }}
              </a>
            </b-form-group>
          </b-col>
          <b-col v-if="inputVisibilitySettings.belongToProduct" cols="12" lg="6">
            <b-form-group>
              <b-form-checkbox v-model="isBelongToProduct">
                {{ $t('product_show_when_product_chosen') }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="isBelongToProduct && product"  :label="$t('event_manage_products')" class="mt-4">
              <b-dropdown id="dropdown-Checkbox" ref="dropdown" class="w-100" :text="selectedProducts.length ? (selectedProducts.length + ' ' + $t('event_products_selected')) : $t('event_products_no_selected')">
                <b-form-group class="allowed-events-container">
                  <b-form-checkbox-group v-model="selectedProducts" id="checkbox-group-2" name="flavour-2">
                    <b-form-checkbox
                      v-for="pr in products.filter(p => p.id !== product.id)"
                      :key="pr.id"
                      :value="pr.id" class="w-100">{{ pr.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-dropdown>
            </b-form-group>
          </b-col>
          <b-col v-if="inputVisibilitySettings.limitAmountToMaximumTickets" cols="12" lg="6">
            <b-form-group>
              <b-form-checkbox v-model="product.limit_max_amount">
                {{ $t('product_limit_amount_to_maximum_tickets') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="inputVisibilitySettings.productImage">
          <b-col v-if="product.image" cols="12">
            <b-img fluid :src="product.image_url" class="Myimg"></b-img>
            <a @click="removeProductImage" href="javascript:" class="d-block font-16 mt-3">
              {{ $t('product_remove_image') }}
            </a>
          </b-col>
          <b-col v-else>
            <b-form-group>
              <label class="col-form-label d-block">{{ $t('product_image') }} <b-icon id="product-image" v-b-tooltip.hover class="ml-2" icon="info-circle-fill"></b-icon></label>
              <b-tooltip target="product-image" triggers="hover">
                {{ $t("product_image_tooltip") }}
              </b-tooltip>

              <input type="file" id="image" accept="image/png, image/jpeg, image/jpg" ref="image" v-on:change="onImageChange()"/>

              <span v-if="state.image" class="text-danger small d-block mt-2">{{ $t(state.image) }}</span>
            </b-form-group>
          </b-col>
          <b-col v-if="saving">
            <b-icon icon="arrow-clockwise" animation="spin" font-scale="2"></b-icon>
          </b-col>
        </b-row>
        <div v-if="errors.length" class="mt-2">
          <div v-for="error in errors" :key="error.message" class="text-danger small">{{ $t(error.message, error.params) }}</div>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="saveProduct()" :disabled="event.isArchived || state.image !== null || state.email !== null" variant="success">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
    <b-modal :title="$t('product_modal_create')" id="new-product" size="lg">
      <b-form-group v-if="product" :label="$t('product_name')">
        <b-form-input v-model="product.name"></b-form-input>
      </b-form-group>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="createProduct()" :disabled="event.isArchived" variant="success">{{ $t('create') }}</b-button>
      </template>
    </b-modal>
    <b-modal :title="$t('product_modal_delete')" id="delete-product" size="lg">
      <div>
        <b-row>
          <b-col>{{ $t('product_delete_confirmation') }}</b-col>
        </b-row>
      </div>
      <template #modal-footer="{cancel}">
        <b-button @click="cancel()" variant="warning">{{ $t('cancel') }}</b-button>
        <b-button @click="deleteProduct()" :disabled="event.isArchived" variant="danger">{{ $t('delete') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>


<script>
import api from '../api'
import StatusLabel from "@/components/StatusLabel";
import EventNav from "@/components/EventNav";
import axios from 'axios'
import DateTimePicker from "@/components/DateTimePicker";
import moment from 'moment'
import {mapActions,mapState} from "vuex";
import {deepClone, validateEmail} from "@/functions";

export default {
  components: {
    DateTimePicker,
    StatusLabel,
    EventNav
  },
  name: 'ManageEvent',
  data() {
    return {
      saving: false,
      event: null,
      product: null,
      productGroups: null,
      no_products: true,
      selected_product_id: null,
      productTypes: [],
      ticket_fee_incl: 0,
      templates: [],
      vat: null,
      vats: [
        {value: '0', text: '0%'},
        {value: '6', text: '6%'},
        {value: '9', text: '9%'},
        {value: '19', text: '19%'},
        {value: '21', text: '21%'},
        {value: 'different', text: this.$t('different')}
      ],
      state: {
        vat: null,
        image: null,
        email: null
      },
      isBelongToProduct: false,
      selectedProducts: [], 
      inputVisibilitySettings: {
        generateETicket: false,
        ticketTemplate: false,
        scanColor: false,
        productImage: false,
        minMaxPerOrder: false,
        belongToProduct: false,
        salesSets: false,
        sendAdditionalTicket: false,
        sendConfirmationEmail: false,
        scanTillDateTime: false,
        limitAmountToMaximumTickets: false
      },
      isSendAdditionalTicket: false,
      additionalTicketsSelectedProducts: [],
      errors: [],   
    }
  },
  created() {
    document.title = this.$t('backend_title_events_products')
    if(this.$store.state.user) {
      if(!this.$can('event_products_view')) {
        this.$store.commit('errors', [this.$t('not_have_permission')])
        this.$router.push('/403');
        return;
      }
    }
    api.event().productsDependencies(this.$route.params.id).then(response => {
      if( this.user && this.user.company && this.user.company.country === 'nl') {
        this.ticket_fee_incl = Number(response.ticket_fee + (response.ticket_fee * (response.vat_percentage / 100))).toFixed(2)
      }
      else {
        this.ticket_fee_incl = Number(response.ticket_fee).toFixed(2)
      }
    })
    api.event().get(this.$route.params.id).then(event => {
      this.event = {...event,isArchived :( moment(event.ends_at).isBefore(moment()) && !event.continuous) || event.status === "archived"}
    })
    api.ticket().getTemplates().then((templates) => {
      this.templates = [{id: null, name: 'Inherent from event'}].concat(templates)
    })
    this.getProducts()
    this.getProductTypes();
    this.loadProductTypeDonation()
  },
  methods: {
    trim (str) {
      return str.replace(/^\s+|\s+$/gm,'');
    },
    rgbaToHex (rgba) {
      var inParts = rgba.substring(rgba.indexOf("(")).split(","),
          r = parseInt(this.trim(inParts[0].substring(1)), 10),
          g = parseInt(this.trim(inParts[1]), 10),
          b = parseInt(this.trim(inParts[2]), 10),
          a = parseFloat(this.trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
      var outParts = [
        r.toString(16),
        g.toString(16),
        b.toString(16),
        Math.round(a * 255).toString(16).substring(0, 2)
      ];

      // Pad single-digit output values
      outParts.forEach(function (part, i) {
        if (part.length === 1) {
          outParts[i] = '0' + part;
        }
      })

      return ('#' + outParts.join(''));
    },
    newProduct() {
      this.state.image = null;
      this.product = {}
      this.$bvModal.show('new-product')
    },
    getProducts() {
      api.product().getGroups(this.$route.params.id).then(groups => {
        this.productGroups = groups.filter((group) => {
          this.no_products = false
          return group.products.length > 0
        })

        let no_product_group = this.$t('no_product_group')
        this.productGroups.forEach(function (part, index, groups) {
          if (part.type == 'default') {
            groups[index].name = no_product_group
          }
        });
      })
    },
    async onImageChange() {
      this.state.image = null;
      const validation = await this.validateImage(this.$refs['image'].files[0]);
      if(validation.size > 4e+6) { // 4e+6 is 4 MB
        this.state.image = 'product_image_validate_size';
        return;
      }
      if(validation.width > 120 || validation.height > 120) {
        this.state.image = 'product_image_validate_resolution';
        return;
      }
      this.saving = true;

      api.cdn().signUpload(this.$refs['image'].files[0].name).then((response) => {
        // Upload the file
        axios.create().put(response.upload_url, this.$refs['image'].files[0], {
          headers: {
            'Content-Type': this.$refs['image'].files[0].type
          }
        }).catch((e) => {
          // Upload failed...
          console.error(e)
          this.saving = false;
        }).then(() => {
          // upload done, create the video in the database
          this.product.image = response.path
          this.product.image_url = response.target_url
          this.saving = false;
        })
      })
    },
    onSpecificSale(){
      this.product.sale_show_after_expire = true;
    },
    removeScanColor(){
      this.product.scan_color = null
    },
    removeProductImage() {
      this.saving = true;
      api.cdn().deleteUpload(this.product.image).then(() => {
        this.product.image = null;
        this.product.image_url = null;
        this.saving = false;
        this.saveProduct(true);
      }).catch(() => {
        this.saving = false;
      });
    },
    getProductTypes() {
      api.product().getProductTypes().then(productTypes => {
        this.productTypes = productTypes
      })
    },
    editProduct(p) {
      this.inputVisibilitySettings.generateETicket = false;
      this.inputVisibilitySettings.ticketTemplate = false;
      this.inputVisibilitySettings.productImage = false;
      this.inputVisibilitySettings.scanColor = false;
      this.inputVisibilitySettings.belongToProduct = false;
      this.inputVisibilitySettings.salesSets = false;
      this.inputVisibilitySettings.sendAdditionalTicket = false;
      this.inputVisibilitySettings.sendConfirmationEmail = false;
      this.inputVisibilitySettings.scanTillDateTime = false;
      this.inputVisibilitySettings.limitAmountToMaximumTickets = false;

      this.state.image = null;

      const product = deepClone(p);
      this.selectedProducts = product.parents.map(p => p.parent_id);
      this.isBelongToProduct = !!this.selectedProducts.length;
      this.inputVisibilitySettings.belongToProduct = !!this.selectedProducts.length;
      this.additionalTicketsSelectedProducts = product.additions.map(p => p.child_id);
      this.isSendAdditionalTicket = !!this.additionalTicketsSelectedProducts.length;
      this.inputVisibilitySettings.sendAdditionalTicket = !!this.additionalTicketsSelectedProducts.length;
      this.inputVisibilitySettings.scanTillDateTime = !!product.scan_till_date_time;
      this.inputVisibilitySettings.limitAmountToMaximumTickets = !!product.limit_max_amount;

      this.inputVisibilitySettings.minMaxPerOrder = product.min_buy !== 0 || product.max_buy !== 20;

      if (product.group_id) {
        let group = this.productGroups.find(group => {
          return group.id === product.group_id
        })
        if (group) {
          product.group_name = group.name
        }
      }
      product.calculate_service_fee = false;
      if(product.service_fee){
        product.calculate_service_fee = true;
      }
      if (product.sale_starts_at) {
        product.specific_sales_time = true
      }
      if (product.sale_ends_at) {
        product.specific_sales_time = true
      }
      if (product.create_eticket === null) {
        product.create_eticket = true;
      }
      if (product.max_buy === null) {
        product.max_buy = 20;
      }

      if(product.ticket_template_id) {
        this.inputVisibilitySettings.ticketTemplate = true;
      }
      if(product.scan_color) {
        this.inputVisibilitySettings.scanColor = true;
      }
      if(product.image_url) {
        this.inputVisibilitySettings.productImage = true;
      }
      if(product.sale_sets) {
        this.inputVisibilitySettings.salesSets = true;
      }
      if(this.isBelongToProduct){
        this.belongToProduct = true;
      }
      if(product.confirmation_email){
        this.inputVisibilitySettings.sendConfirmationEmail = true;
      }
      

      this.vat = null;
      if(![parseFloat('0'), parseFloat('6'), parseFloat('9'), parseFloat('19'), parseFloat('21')].includes(parseFloat(product.vat_percentage))) {
        this.vat = deepClone(product).vat_percentage;
        product.vat_percentage = 'different';
      }
      this.product = Object.assign({}, product)
      this.$bvModal.show('edit-product')
    },
    saveProduct(keep = false) {
      this.errors = [];
      if (this.product.group_name === this.$t('no_product_group')) {
        this.product.group_name = '';
      }
      if(!this.product.calculate_service_fee){
        this.product.service_fee = 0
      }
      if(this.product.vat_percentage === 'different') {
        if(!this.vat) {
          this.state.vat = false;
          return;
        }
        this.product.vat_percentage = this.vat ?? 0;
      }
      if(!this.isBelongToProduct) {
        this.selectedProducts = [];
      }
      this.product = Object.assign(this.product, {dependency_products: this.selectedProducts, products_additions: this.additionalTicketsSelectedProducts});
      api.product().save(this.$route.params.id, this.product).then((response) => {
        if(response.errors && response.errors.length > 0) {
          this.errors = response.errors;
          return;
        }
        if(keep === false) {
          this.getProducts()
          this.$bvModal.hide('edit-product')
          this.product = null
        }
        this.$store.commit('success', 'product_saved')
      })
    },
    createProduct() {
      api.product().create(this.$route.params.id, this.product).then((product) => {
        this.getProducts()
        this.$bvModal.hide('new-product')
        if(this.$can('event_products_edit')) {
          this.editProduct(product)
        }
      })
    },
    showDeleteModal(product_id) {
      this.$bvModal.show('delete-product')
      this.selected_product_id = product_id
    },
    deleteProduct() {
      this.$bvModal.hide('delete-product')

      api.product().delete(this.$route.params.id, this.selected_product_id).then(() => {
        this.selected_product_id = null
        this.getProducts()
        this.$store.commit('success', 'product_has_been_deleted')
      })
    },
    handleProductTypeChange(selectedType) {
      if (selectedType === this.donation_product_id) {
        this.product = Object.assign(this.product, {
          specific_sales_time: false,
          sale_starts_at: null,
          sale_ends_at: null,
          scan_till_date_time: null,
          stock: -1,
          max_buy: 1,
          min_buy: 0,
          price: 0,
        })
      }
    },
    validateVat(value) {
      this.state.vat = null;
      if(parseFloat(value) > 100) {
        this.vat = 100;
        value = 100;
      }
      else if(parseFloat(value) < 0) {
        this.vat = 0;
        value = 0;
      }
    },
    validateImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // When the file has been loaded
        reader.onload = (event) => {
          const img = new Image();

          // When the image has been loaded
          img.onload = () => {
            resolve({ size: file.size, width: img.width, height: img.height });
          };

          // Set the source of the image
          img.src = event.target.result;
        };

        reader.onerror = (event) => {
          // Reject the Promise with the error
          reject(event.target.error);
        };


        // Read the file as a data URL
        reader.readAsDataURL(file);
      });
    },
    validateEmail(email) {
      this.state.email = null;
      if(!email) {
        return;
      }
      if(!validateEmail(email)) {
        this.state.email = false;
      }
    },
    ...mapActions(['loadProductTypeDonation'])
  },
  computed:{
    products() {
      let products = [];
      this.productGroups.forEach(group => {
        products = products.concat(group.products);
      })
      return products;
    },
    ...mapState(['donation_product_id', 'user']),
  },
  watch: {
    '$store.state.user': {
      handler() {
        if(!this.$can('event_products_view')) {
          this.$store.commit('errors', [this.$t('not_have_permission')])
          this.$router.push('/403');
        }
      }
    }
  }
}
</script>
<style>
#edit-product{
  padding-left: 0px!important;
}
</style>
<style scoped>
div.one-colorpicker div.one-colorpanel div.one-color-value div.one-type-hex{
  display: block !important
}
div.one-colorpicker div.one-colorpanel div.one-color-value div.one-type-rgb{
  display: none !important
}  
/deep/ .one-color-stash-wrap ul li.transparent, /deep/ .one-opacity{
  display: none !important
}
/deep/ .one-color-toggle{
  display: none !important;
  width:0;
  height:0;
}
  .responsive th:nth-of-type(1){
  width: 15%; color:white;
  }
  .responsive th:nth-of-type(2){
  width: 30%; color:white;
  }
  .responsive th:nth-of-type(3){
  width: 15%; color:white;
  }
  .responsive th:nth-of-type(4){
  width: 15%; color:white;
  }
  .responsive th:nth-of-type(5){
  width: 15%; color:white;
  }
.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid transparent!important;
}

.table tr{
  border-bottom:1px solid #DEE2E6;
}
.table td{
  border-top: 0;
}
.flexing{
  display: flex;

}
.text-black {
  color: #4D4D4D;
}
.input-group-text{
  border:0
}
.manage-btn {
  float: left;
  margin-right: 10px !important;
}
.delete-btn {
  float: left;
  cursor: pointer;
}
i.hint-text{
  font-size:9pt
}
.font-14 {
  font-size: 14px;
}

tbody:hover button{
  background-color: unset !important;
  color: unset!important;
  border-color: #ddd!important;
}
tbody button:hover{
  background-color: #F0B52D !important;
  color: #fff!important;
  border-color: #F0B52D!important;
}
.Myimg{
  max-width:100%;
 width:auto;
}
@media (max-width: 1550px){
h2{
  font-size: 2rem;
}
 .responsive th:nth-of-type(5){
  width: 25%; 
  }
}
@media (max-width: 1000px){
table.b-table thead tr th:first-child, table.b-table tbody tr td:first-of-type {
    padding-left: 0.75rem;
    display: flex;
    justify-content: center;
}
table.b-table tbody tr td:last-child{
  padding: 15px;
}
.manage-btn,.delete-btn{
  float: unset;
}
h2 {
    margin-bottom: 2rem;
}
}
@media (max-width:576px) {
  
img.event-image,.Myimg{
  min-width:100%;
}
}
.allowed-events-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 250px;
}
</style>
